import React from "react";
const TermsOfUsePage = (props) => {
  return (
    <>
      <div className="container pb-4 pt-5 policy-page">
        <div className="mb-5 text-center">
          <h1>Velas Pad Limited</h1>
          <h2>Terms and Conditions of <a href="https://velaspad.io/">https://velaspad.io/</a></h2>
          <small>Last revised Date: 21<sup>st</sup> September 2021</small>
        </div>

        <div style={{ lineHeight: 1.7 }}>
          <ol>
            <li>
              <b>Introduction</b>
              <p>These Terms and Conditions (herein referred to as <b>“Terms”</b>) govern the use and the conditions of <a href="https://velaspad.io/">https://velaspad.io/</a> (herein referred to as <b>“Website”</b>), and the Services provided by Velas Pad Limited (herein referred as <b>“Velas”</b> or <b>“we”</b> or <b>“us”</b> or <b>“our”</b>, which shall have the same meaning in these Terms), a company incorporated and registered under the laws of Republic of Seychelles. These Terms constitute a binding and enforceable legal contract between Velas and its Affiliate and subsidiaries worldwide and you, an end user of the Services (herein referred to as <b>“you”</b> or <b>“user”</b>) in relation to the Services. You and Velas are referred to separately as <b>“Party”</b> and collectively as <b>“Parties”</b>.</p>
              <p>By accessing, registering, using, or clicking on the Services, and information made available by Velas via the Website, you hereby accept and agree to all the terms set forth herein.</p>
              <p>You are strongly advised to carefully read these Terms as well as the provisions detailed in our Privacy Policy prior to using the Website and our Services. By using the Website and the Services in any capacity, you agree that: (i) You have read and familiarized yourself with these Terms; (ii) You understand these Terms; and (iii) You agree to be bound by these Terms when using the Website. If You do not agree to these Terms, please do not access or use the Website and the Services.</p>
              <p>We reserve the right to modify or amend these Terms, the Website, or any content on either one of the platforms from time to time, including for security, legal, or regulatory reasons, as well as to reflect updates or changes to the Services or functionality of the Website. You are advised to check these Terms periodically to ensure that you are cognizant of the current versions and comply with them. Users of the Website and the Services are bound by these changes which will take immediate effect after the revised versions of these Terms have been published on the Website. Through your continued use of or interaction with the Website, the Services, tools, and information made available on the Website, you hereby agree to be bound by the provisions highlighted in the subsequent versions.</p>
              <p>We will provide a notification on the Website specifying that changes have been made to these Terms whenever they occur. By accepting the notification, you accept that we have provided you with sufficient notice of any changes. You should seek professional advice regarding any possible legal requirements you must comply with in relation to use of the Website, and tools that related to participating in the Services provided by us or the Partners.</p>
            </li>
            <li>
              <b>Definitions</b>
              <p><b>"ADAPAD"</b> refers to the platform for Cardano Smart Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at <a href="https://adapad.io">https://adapad.io/</a>.</p>
              <p><b>"Affiliates"</b> refers to in relation in any party, any other company which, directly or indirectly, (i) is controlled by that party, (ii) controls that party, or (iii) is under common control with that party, and in respect of us, shall also include any fund, limited partnership or other collective investment vehicle or other person which is managed or advised by us.</p>
              <p><b>"AML"</b> refers to anti-money laundering.</p>
              <p><b>"Applicable Laws"</b> refers to acts, statutes, regulations, ordinances, treaties, guidelines, and policies issued by governmental organizations or regulatory bodies, including, but not limited to, the governing law stipulated under Laws of the Republic of Seychelles.</p>
              <p><b>"Bridge Service"</b> refers to the service provided by us and our Affiliate in Clause 6.</p>
              <p><b>“BSCPAD Platform”</b> refers to the platform for Binance Smart Chain Network, which is a platform for retail investment into tokens offered via the IDO. Please find further details at <a href="https://bscpad.com/" target="_blank">https://bscpad.com/</a>.</p>
              <p><b>"CFT"</b> refers to Combating the Financing of Terrorism.</p>
              <p><b>"Confidential Information"</b> refers to any non-public, proprietary information or documents of or related to the Parties (whether in writing, orally or by any other means) by or on behalf of the user to us and which if disclose in tangible or intangible form is marked confidential (including in visual, oral, or electronic form) relating to us or any other user that was previously a user, which is provided or disclosed through us (or to any employees or agents) in connection with the use or participate in the Services.</p>
              <p><b>"Content"</b> refers to all content generated by us, including logos, identifying marks, images, illustrations, designs, icons, photographs, videos, texts, any written or multimedia material, advertisements, software, codes, data, files, archives, folders, or available downloads on the Website.</p>
              <p><b>"Cookie"</b> refer to the small text files that are placed on your computer by the Website that you visit. They are widely used in order to make Websites work, or work more efficiently, as well as to provide information to the owner of the site.</p>
              <p><b>"CrossSwap"</b> refers to the cross-chain swap designed to unify the trading service on one platform. Please find further details at <a href="https://crossswap.com/" target="_blank">https://crossswap.com/</a>.</p>
              <p><b>"CrossWallet"</b> refers to the non-custodian wallet service that holds any digital asset you prefer to hold, trade, or send, from tokens to NFTs, which it works seamlessly move between different blockchain and works on any device, either mobile or web. Please find further details at <a href="https://crosswallet.app/" target="_blank">https://crosswallet.app/</a>.</p>
              <p><b>"ETHPAD"</b> refers to the platform for Ethereum Smart Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at <a href="https://ethpad.network/" target="_blank">https://ethpad.network/</a>.</p>
              <p><b>"FCFS Round"</b> refers to First Come First Serve Round of the the Company fundraising project token allocation for the unsold IDO project tokens left from the TRONPAD Tiered System.</p>
              <p><b>"IDO"</b> refers to Initial DEX Offerings or Initial Decentralized Exchange Offerings.</p>
              <p><b>"KCCPAD"</b> refers to the platform for KuCoin Community Chain Network, which is a platform for retail investment into tokens offered via the IDO. Please find further details at <a href="https://kccpad.com/" target="_blank">https://kccpad.com/</a>.</p>
              <p><b>"Partners"</b> refers to BSCPAD, ETHPAD, KCCPAD, TRONPAD, ADAPAD, NFTLaunch, CrossWallet, CrossSwap, and KCCSwap.</p>
              <p><b>"Prohibited Jurisdictions"</b> specifically refer to Albania, Barbados, Botswana, Burkina Faso, Cambodia, Democratic People’s Republic of Korea, Haiti, Iran, Jamaica, Morocco, Myanmar (Burma), Nicaragua, Pakistan, Panama, Philippines, Senegal, South Sudan, Syria, Thailand, Uganda, Yemen, Zimbabwe, and the United States of America.</p>
              <p><b>"Project or IDO Project"</b> refers to a Velas Project being launched for an IDO event on VELASPAD Platform.</p>
              <p><b>"Services"</b> refer to the services provided to the users through the Website, which include the accessibility to the new IDO Project’s tokens offered from each Project, Staking service, cross-chain bridge service, and other relevant services available through the VELASPAD Platform, which may be subject to periodic revision.</p>
              <p><b>"Staking or Stake"</b> refers to the staking or deposit service in which the users can delegate or deposit the user’s VLXPAD Tokens in exchange for  rewards, and opportunities to participate in the Services on the VELASPAD Platform.</p>
              <p><b>"TRONPAD"</b> refers to the platform for Tron Smart Chain Network, which is a platform for retail investment into tokens offered via IDO. Please find further details at <a href="https://tronpad.network/" target="_blank">https://tronpad.network/</a>.</p>
              <p><b>"NFTLaunch"</b> refers to the platform for deflationary NFT Launchpad which is the first platform to offer zero gas wars, fair distribution and free NFT airdrops of new and upcoming NFT projects. Please find further details at <a href="https://nftlaunch.network/" target="_blank">https://nftlaunch.network/</a>.</p>
              <p><b>“VELASPAD Platform”</b> refers to the Website, or platform where Velas IDO Project is officially launched, and makes available its native tokens to be distributed to the users in the allocation round in order to be swapped for other crypto assets or enjoy the cross-chain integration service.</p>
              <p><b>“VLXPAD Token”</b> refers to a blockchain - based token which is issued, stored, transferred, transacted Velas Chain Network, which is built on the Solana codebase but augmented with a custom-built AI-powered Proof-of-Stake (AIDPOS) consensus system. Users need to stake VLXPAD Tokens at least three (3) hours before the IDO Project begins.</p>
              <p><b>"Wallet(s)"</b> refers to a wallet which must be compatible with the Velas Chain Network, such as MetaMask.</p>
              <p><b>“Whitelist Requirements”</b> refer to activities which require users who wish to participate and be granted opportunities for purchasing IDO Project’s tokens. The activities include Twitter like, Comment, and Retweet.</p>
            </li>
            <li>
              <b>General Provisions</b>
              <ol>
                <li>
                  Contractual Relationship
                  <p>These Terms constitute a valid and binding agreement between the Parties. The binding obligations stipulated in these Terms are enforceable.</p>
                </li>
                <li>
                  Revision and Amendments
                  <p>We reserve the right to revise, amend, or update any clauses and provisions stipulated in these Terms at its sole discretion at any time. We may notify the revision or amendment of such clauses or provisions by updating these Terms and specify the 'Last Revised Date' displayed on a page of Terms and Conditions. Any revisions and updates on these Terms will be automatically effective upon their publication on the Website. Therefore, your continuity of accessing or using the Website, and Services will be deemed that you agree to have read, understood, and accepted all revised terms and provisions. If you do not agree on any revised or updated terms and provisions, you should immediately stop accessing or using our Services. You are encouraged to frequently and carefully review these Terms to ensure that you understand these Terms.</p>
                </li>
                <li>
                  Privacy Policy
                  <p>You acknowledge and confirm that you have read, understood, and agreed to provisions stipulated in the Privacy Policy, which will explain how we treat your information and protects your privacy when accessing or using the VELASPAD Platform.</p>
                  <p>By using the VELASPAD Platform, you hereby agree that we may collect, use your information, and anonymised data pertaining to your use of the Services for analytics, trends, identification, and purpose of statistics to further enhance the effectiveness and efficiency of the Services.</p>
                  <p>You hereby expressly authorise us to disclose any and all information relating to you in our possession to any law enforcement or government officials upon the request by the court order.</p>
                </li>
                <li>
                  Links to and from the Website
                  <ol>
                    <li>You may, through hypertexts or other computer links, gain access form the Website to websites operated or made available, or otherwise licensed by persons other than us (“Third Party Services”). Such links are provided for your convenience.</li>
                    <li>A link from the Third Party Services does not mean that we endorse or approve the content on such website or does not mean we are an operator of that Website. You understand that you are solely responsible for determining the extent to which you may use or rely upon any content at any other Third Party Services’ websites which you have accessed from the  Website. We have no control over the content of these Third Party Services’ sites or resources and accept no liability for them or for any loss or damage that may arise from your use of them.</li>
                    <li>We assume no responsibility for the use of, or inability to use, any Third Party Services’ software, other materials, or contents posted and/or uploaded on such website and we will have no liability whatsoever to any person or entity for any inaccuracy or incompleteness of such Third Party Services’ content. All intellectual property rights in and to Third Party Services are property of the respective third parties.</li>
                  </ol>
                </li>
                <li>
                  Disclaimer for Accessibility of the Website and the Services
                  <ol>
                    <li>The Website merely facilitates the users accessibility to the IDO Project’s tokens and does not provide you with any warranty or representation whatsoever regarding its quality, value, specification, fitness for the purpose, completeness or accuracy of its technology or infrastructure of such Project’s tokens.</li>
                    <li>We will make all commercially reasonable attempts to facilitate information about the Project on the VELASPAD Platform. However, you hereby acknowledge that we do not guarantee the accuracy, timeliness, or completeness of such information, and does not provide any warranty in connection with your use or reliance on such information. You agree that your use of the Project information will be at all your own risk. We will not be liable to you in any manner for the termination, interruption, delay, or inaccuracy of any Project information launched on the VELASPAD Platform.</li>
                    <li>To the extent permitted by applicable laws, the Website and our Services are provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We do not warrant that the features, and functions contained on the Website and the Services will satisfy your preferences you hereby agree and acknowledge that your access and use of the Website and the Services are at your own risk, and you will be liable for any responsibility, consequences that may arise out of or in connection with the usage or accessibility of the Website and the Services. You expressly agree that we will have absolutely no liability in this regard.</li>
                    <li>You hereby agree and acknowledge that the Website may contain links to a Third-Party Service’s website or services that are not owned or even controlled by us, thus we will hold no responsibility for the content or services, goods, or activities provided by such Third-Party Service’s website. You further agree that we have no liability directly or indirectly for any damage, loss, or consequence arising out of or in connection with the usage or reliance of any content, material, services available through the Third-Party Service’s website.</li>
                    <li>We reserve the right to limit the availability of the Website to any person, geographic area, or jurisdiction we so desire and/or terminate your access to and use of the Website and the Services, at any time and in our sole discretion.</li>
                    <li>We may, at our sole discretion, impose limits or restrictions on the use you make of the Website. Further, for commercial, security, technical, maintenance, legal or regulatory reasons, or due to any breach of these Terms, we may withdraw the Website or your access to the Website and the Services at any time and without notice to you.</li>
                  </ol>
                </li>
                <li>
                  Information
                  <p>You acknowledge that you are solely responsible for any submissions of all contents, remarks, suggestions, ideas, materials, feedbacks, or other information, including bug reports in relation to the Services provided through the Website including any submission to our social media platforms such as Twitter and Telegram, and you, not us, have full responsibility for such submissions, including their accuracy, legality, reliability, appropriateness, originality, and copyright. We shall reply on the information you have provided and will not verify it. Notwithstanding the foregoing, we have the right to refuse to post, remove, edit, or abridge any submission for any reason and to freely use, copy, disclose, publish, display, or exploit such submission as we deem necessary without any payment of royalty, acknowledgement prior to consent, we may retain copies of all information materials relevant to the Service.</p>
                </li>
              </ol>
            </li>
            <li>
              <b>Intellectual Property</b>
              <ol>
                <li>All present and future copyright, title, interests in and to the Services, registered and unregistered trademarks, design rights, unregistered designs, database rights and all other present and future intellectual property rights and rights in the nature of intellectual property rights that exist in or in relation to the use and access of the Website and Services are owned by or otherwise licensed to us. Subject to your compliance with these Terms, we grant you a non-exclusive, non-sub license, and any limited license to merely use or access the Website and the Services in the permitted hereunder.</li>
                <li>Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to you or any other third party’s intellectual rights.</li>
                <li>If and to the extent that any such intellectual property rights are vested in you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably request in order to assign such intellectual property rights back to us.</li>
                <li>You agree and acknowledge that all Website content must not be copied or reproduced, modified, redistributed, used, created for derivative works, or otherwise dealt with for any other reason without being granted a written consent from us.</li>
                <li>Third parties participating on the Website may permit us to utilise trademarks, copyrighted material, and other Intellectual Property associated with their businesses. We will not warrant or represent that the content of the Website does not infringe the rights of any third party.</li>
              </ol>
            </li>
            <li>
              <b>Participation in the our Services</b>
              <ol>
                <li>By using or accessing the Services, you represent and warrant that you understand that there are inherent risks associated with digital currencies, and the underlying technologies, including, without limitation, cryptography and blockchain, and you agree that we, the community, and open-source code contributors are not responsible for any loss or damage associated with these risks.</li>
                <li>You specifically acknowledge and agree to follow the conditions stipulate herein the Website, such as tier system, Staking/Unstaking and other conditions which we may update from time to time.</li>
              </ol>
            </li>
            <li>
              <b>Bridge Service</b>
              <ol>
                <li>
                  Upon completion of the registration and identity verification for your Wallet, you may use our Services, including but not limited to, Staking, and accessing to other information released by us, participating in any launchpad, token swaps, bridge, etc., in accordance with the provision of the bridge function.
                  <p>Under the bridge function, You will be required to conduct the following provisions for the purpose of double verification and convenience crossing between two platforms:</p>
                  — Connect your Wallet (by clicking the button shown on the Website) with our Partner’ service; and<br />
                  — Synchronize your KYC/AML/CFT with our Partners;
                  <p>You hereby acknowledge and agree that we will not be liable for any damage, loss, consequence, or devaluation of the assets held in any of your Wallet under the provision of Partners’ services, which may occur as a result or in connection with any matter related to disruptive function, broken internet system or any failure.</p>
                </li>
                <li>In term of KYC/AML/CFT synchronize, you understand and acknowledge that you agree to share your KYC/AML/CFT information among our Partners for the purpose of identity verification through KYC/AML/CFT process. Therefore, you hereby agree that when you use our synchronize service, you will inform us for any KYC/AML/CFT information changed (if any).</li>
                <li>Upon sending an instruction of our Service for KYC/AML/CFT synchronize, your account will be immediately updated to reflect the KYC/AML/CFT profile, and your KYC/AML/CFT profile will be included in Partners’ data to match the identity verification through KYC/AML/CFT requirement. Once the instruction is executed, your account will be updated to reflect that the instruction has been fully match and passed the KYC/AML/CFT verification and you are ready to experience our Service. The instruction will remain uncompleted until it is fully filled with a Wallet address. To provide the Wallet address, you authorized our Partners to confirm and temporarily control the data transfer to us.</li>
                <li>You are only allowed one time to synchronize KYC/AML/CFT to any launchpad provided by our Partners. Double check the address input in the box, whether it is the address showing in your Wallet. If you need to synchronize again or update any information, please contact our supporting team, detail provided in Clause 15.</li>
                <li>
                  Unless otherwise specified by us, to use Bridge Service, you must complete the identification verification through KYC/AML/CFT process or passed the KYC/AML/CFT synchronized from any Partners for the VELASPAD Platform’s account. You acknowledge and agree that:<br /><br />
                  a. you fully understand the swapping process, including but not limited to the risk or major fluctuation of digital asset in swapping, and the risk of exacerbated adverse outcome when leverage is used;<br />
                  b. you have sufficient technological knowledge and experience and the capacity to understand the process and agree to independently assume all the risks arising from the Bridge Service;<br />
                  c. You agree and authorize us to take various reasonable measures in its discretion (including but not limited to blocking or freezing the swapping requests under specific circumstances) in accordance with the our decision to protect the legitimate interests of you, us itself and other users.
                </li>
                <li>Prior to conducting the Bridge Service for swap your asset, in accordance with type of asset swapping, you shall provide the amount for swapping that shall comply with the daily limit (show on the BRIDGE page). Unless otherwise specified by us, to use the Bridge Service, you agree to keep enough asset in your Wallet, as required by engaging in the swapping process. Failure to keep enough assets may result in cancellation of the swapping request.</li>
              </ol>
            </li>
            <li>
              <b>Identity Verification through KYC/AML/CFT Process</b>
              <ol>
                <li>As a software development company, we have no role in enforcing KYC/AML/CFT by default, however, we have a mandatory requirement for Identification Verification KYC/AML/CFT tool for the IDO Projects companies provided on our VELASPAD Platform to enforce on the users. We implement KYC/AML/CFT tool into our launchpad through <a href="https://kycaid.com/" target="_blank">https://kycaid.com/</a>.</li>
                <li>The use of KYC/AML/CFT tool by IDO Projects companies using the Services on VELASPAD Platform is not the discretion of said entitles, and they must require you to complete KYC/AML/CFT verification process before participating in any IDO Project’ s event.</li>
                <li>
                  Although we make no warranty as to the merit, legality, or juridical nature of any Project’s token, we nonetheless understand the need of IDO Projects to require KYC/AML/CFT Verification on their token sale participants of users. Therefore, we reserve the right:
                  <ol>
                    <li>at any time, to ask for your personal information, Name-Surname, Birthday,-e- mail address, nationality, location, government identification number (Identification Card/Passport number and Date of Identification Card/Passport issuing), telegram username, Wallet address, and any KYC/AML/CFT documentation with the liveness test that it deems necessary to determine the identity and location of the user, and reserves the right to restrict Services and payment until identity is sufficiently determined;</li>
                    <li>The liveness test shall mean taking a photo of your government identification with your phone or camera. Then, also take a photo of yourself (selfie of your face) holding your ID document and the paper you wrote on next to your face (not covering your face), the piece of paper shall write down the (1) VELASPAD, (2) the current date, and (3) the last 4 characters of your Wallet address”.</li>
                    <li>to share the submitted KYC/AML/CFT information and documentation to the third parties to verify the authenticity of submitted information, and the end user (you) agree to this by using the Services;</li>
                    <li>to reject the use of the Services that we have the reasonable ground to believe that they are found to be in violation of relevant and applicable AML/CFT laws and regulations, and to cooperate with the competent authorities or any investigation when and if necessary, upon the valid request by the court order.</li>
                  </ol>
                </li>
                <li>We expressly prohibit and reject the use of the Services for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations, consistent with various jurisdictions’ laws, regulations and norms. To that end, the Services are not offered to individuals or entities on any Politically Exposed Persons (“PEP”) lists, or subject to any United States, European Union, or other global sanctions or watch lists. By using the Service, you represent that you are not on any such lists.</li>
                <li>You fully acknowledge that your information and KYC/AML/CFT documentation may be disclosed to government agencies or regulator upon the only valid request of the court order. Once you have decided to participate in any IDO Project’s event and start staking your VLXPAD Tokens, you must ensure that all information provided to us is complete, accurate, and updated in a timely manner. We will rely on the information you provided and should there be any reasonable grounds to believe that the partial or the whole of your information provided to us is incomplete, or incorrect, or outdated, we reserve the right to send you a notice to demand correction, or to delete such information directly, and, as the case may be, to prohibit you to access to all or part of our Website and the Services.</li>
                <li>If we have the reasonable ground to believe that any user transacts or use the Services by using the digital currencies derived from any suspicious illegal activities, we shall be entitled to block your accessibility as necessary. We will hold no liability to such users for any damage, or loss arising out of or in connection with this manner herein. Please note that any attempt to circumvent our measures set out in the Clause 7 will also result in a similar action.</li>
              </ol>
            </li>
            <li>
              <b>Misuse of the Website</b>
              <ol>
                <li>In the event of any misuse and/or abuse of the Website or breach any provision in these Terms, we reserve the right to block your access to the Website and other until the matter is solved.</li>
                <li>Use of the Website for transmission, publication or storage of any material on or via the Website which is in violation of any applicable laws or regulations or any third-party's rights is strictly prohibited, including but not limited to the use of the Website or the transmission, distribution, publication or storage any material on or via the Website in a matter or for the purpose which infringes copyright, trademark, trade secret or other intellectual property rights, is obscene or harmful to minors or constitutes an illegal act or harassment, is libellous or defamatory, violates any privacy or data protections laws, is fraudulent or breaches any exchange control laws.</li>
              </ol>
            </li>
            <li>
              <b>Representations and Warranties</b>
              <p>You hereby agree to make the following representations and warranties by accessing to the Website and/or using the Services:</p>
              <ol>
                <li>You have full capacity and authority under the application laws to agree and bind yourself to these Terms.</li>
                <li>You are eighteen years of age or older.</li>
                <li>You are not a citizen or a resident from the Prohibited Jurisdictions, and you do not have any relevant connection with any jurisdiction where we have prohibited services and access to the Website.</li>
                <li>You are aware and agree to comply with all applicable domestic and international laws, statutes, ordinances, and regulations applicable to your use of the Website and the Services. Your use of the Website and the Services are not for any unlawful or illegal purposes, including but not limited to the usage against the copyright laws, AML/CFT laws.</li>
                <li>You are the exclusive owner of VLXPAD Tokens, and your other cryptocurrencies held in your Wallet. The tokens maintained in your Wallet are not and will not be derived from money laundering, terrorist financing, fraud, or any other illegal activities under any applicable laws. You further hereby acknowledge and agree that we will not be responsible for actions taken by you that result in the loss or destruction of the value of the tokens and rewards you hold in the Wallet.</li>
                <li>You validly undertake any action or enter into any transaction with regard to these Terms. You are solely responsible for use of the Website and the Services for all activities, or transactions that occur on or through your User account on VELASPAD Platform.</li>
                <li>You will provide only accurate, complete, and up-to-date information and documents, if any, for the purposes of accessing or using or participating the Services on the VELASPAD Platform. You will further agree to put your effort to ensure that the confidentiality of your personal or credential information, including your wallet address is restricted, and safely maintained to your device you use to access the Website.</li>
                <li>You will be acknowledged and agree that if you lose access to the Wallet that you connected with the VELASPAD Platform, we will not be able to help you recover these lost, or transfer IDO Project’s tokens or any VLXPAD Tokens back to your Wallet. It will be your solely responsibility to manage your cryptocurrencies stored in your Wallet, and your private key.</li>
                <li>You will be responsible for obtaining the data network access necessary to use the Website. Your network's data and rates and fees may apply if you access or use the Website from a wireless-enabled device, and you will be responsible for such rates and fees.</li>
                <li>You will understand and be aware of risks associated with accessing or using or participating in the Services, and you will be fully liable at your own risk.</li>
                <li>You will be aware that you are subject to tax regulations in the jurisdiction you reside in and will be fully responsible for filling or reporting any taxes and paying them as required by the Applicable Laws. We will not be liable to compensate you for your tax obligations or advise you in relation to your tax obligations. Any uncertainties and unpredictable matters in tax legislation with respect to any token may expose you to any unknown or unforeseeable tax implications associated with your holding of tokens and the use of the Services for which we will have no liability. Moreover, you will not hold us liable for any expenses or losses resulting from unknown or unforeseeable tax implications.</li>
                <li>You will not breach any of the provisions stipulated in these Terms, the Privacy Policy, or any Applicable Laws in any relevant jurisdictions.</li>
                <li>
                  You will not use the Website and the Services in one of any following manners, except as expressly permitted in these Terms, and our discretion.
                  <p>You will not:</p>
                  <p>(a) infringe any propriety rights, including but not limited to copyrights, patents, trademarks, or trade secrets of us.</p>
                  <p>(b) use the Website or the Services to transmit any data or send or upload any material or content that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programmes or similar computer code designed to adversely affect the operation of the Website and the Services provided on the VELASPAD Platform.</p>
                  <p>(c) expressly or impliedly, use the Website and the Services that is deemed unlawful, offensive, malicious, threatening, libellous, defamatory, obscene, or otherwise objectionable or violates these Terms, or any other party's intellectual property.</p>
                  <p>(d) modify, make any back-up or archival copies of the VELASPAD Platform or any part thereof including disassembling, and you will also not adapt, hack the Website or modify another Website to imply that it is associated with the Website falsely.</p>
                  <p>(e) crawl, scrape, or otherwise cache any content from the Website, and you will agree not to use any automated data collection methods, data mining, robots, or scraping or any data gathering methods of any kind on the Website.</p>
                  <p>(f) use the Website or any of its contents for advertising or soliciting, for any other commercial, political, or religious purpose, or to compete, either directly or indirectly with us.</p>
                </li>
                <li>You will defend, indemnify, and not hold us, our Partners, our Affiliate, each of their respective employees, officers, directors, and representatives liable to and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorney's fees), arising out of or relating to any third-party claim concerning these Terms, or your use of the Services in violation of these Terms and applicable laws.</li>
                <li>Each of the User’s Representations and Warranties under this Clause 9 will survive and continue to remain in full force and effect after the termination and/or the expiration of these Terms.</li>
              </ol>
            </li>
            <li>
              <b>Risk Disclosure</b>
              <p>By accessing or using or participating in the Services, you expressly acknowledge and assume the following risks:</p>
              <ol>
                <li>
                  Risk of loss in value
                  <p>Tokens or any digital currencies are not issued by any central banks or national, supra-national, or quasi-national organizations. They are also not backed by any hard assets or other credit. The value of tokens or any digital currencies is affected by several factors, including but not limited to, the total number of tokens or any digital currencies in existence, the continued willingness of market participants to exchange government-issued currency for tokens or digital currencies, purchasers' expectations with respect to the rate of inflation of fiat currencies, purchasers' expectations with respect to the rate of deflation of cryptocurrencies, interest rates, currency exchange rates, cyber theft of cryptocurrencies from online digital wallet providers, or news of such theft from such providers or individuals' digital wallets, investment and trading activities of large investors, monetary policies of the governments, trade restrictions, currency devaluations and revaluations, regulatory measures, the global or regional political, economic or financial events and situations. Thus, all these factors will affect the value of tokens or digital currencies, which may result in the permanent partial or total loss of the value of a particular tokens or digital currencies. No one will be obliged to guarantee the liquidity or the market price of any of the tokens or digital currencies maintained into your Wallet. The volatility and unpredictability of the value of tokens or digital currencies relative to the government-issued currency may result in a significant loss over a short period of time.</p>
                </li>
                <li>
                  The regulatory regime governing tokens or digital currencies
                  <p>The regulatory framework relating to tokens or digital currencies remains unsettled, and any laws, regulations, or guidelines may be significantly revised and amended which will materially and adversely affect the value of tokens or digital currencies and your use of the Services on the Website or VELASPAD Platform.</p>
                </li>
                <li>
                  Technical and System Failure affected the obligations stipulated in these Terms, we  may experience system failures, unplanned interruptions in its Velas Chain Network or services, hardware or software defects, security breaches or other causes that could adversely affect the VELASPAD infrastructure network, which includes the Website and VELASPAD Platform.
                  <p>We are unable to anticipate the occurrence of hacks, cyber-attacks, mining attacks, including but not limited to double-spend attacks, majority mining power attacks and selfish-mining attacks, distributed denial of service attacks or errors, vulnerabilities or defects on the Website, VLXPAD Tokens, users' Wallets or any technology, including but not limited to smart contract technology. Also, we are unable to detect the hacks as mentioned earlier, mining attacks, cyber-attacks, distributed denials of service errors vulnerabilities, or defects in a timely manner and does not have sufficient resources to efficiently cope with multiple service incidents happening simultaneously or in rapid succession.</p>
                  <p>In addition, our network or services could be disrupted by numerous events, including natural disasters, equipment breakdown, network connectivity downtime, power losses, or even intentional disruptions of its services, such as disruptions caused by software viruses or attacks by unauthorized users, some of which are beyond our control. Although we have taken steps and used its best endeavour against malicious attacks on its appliances or its infrastructure, which are critical for the maintenance of the VELASPAD Platform and its Services, there can be no assurance that cyber-attacks, such as distributed denials of service, will not be attempted in the future, and that our enhanced security measures will be effective. Any significant breach of our security measures or other disruptions resulting in a compromise of the usability, stability and security of our network or services, including the VELASPAD Platform, may adversely affect VLXPAD Tokens.</p>
                </li>
                <li>
                  We will have no liability for any delay, error, interruption, or failure to perform any obligation under these Terms where the delay or failure is directly or indirectly resulting from any causes beyond our control, including, but not limited to:
                  <ol>
                    <li>Acts of God, nature, or court of government;</li>
                    <li>Failure or interruption of public or private telecommunication networks, the failure of Velas Chain Network, communication channels or information systems;</li>
                    <li>Acts or omission of acts of a party for whom We are not responsible;</li>
                    <li>Delay, failure, or interruption in, or unavailability of, third-party services;</li>
                    <li>Strikes, lockouts, labour disputes, wars, terrorist acts and riots.</li>
                  </ol>
                </li>
                <li>YOU UNDERSTAND AND AGREE THAT YOUR USE OF OUR SERVICES AND THE WEBSITE IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL CURRENCIES AND THE USE OF SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT, CIRCUMSTANCES, AND FINANCIAL POSITION.</li>
              </ol>
            </li>
            <li>
              <b>Limitation of Liability</b>
              <ol>
                <li>NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT WILL VELASPAD, OUR PARTNERS, OUR AFFILIATE, OR ITS EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR OTHER SUCH WEBSITES, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, OR OTHER SUCH WEBSITES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF YOUR CREDENTIAL INFORMATION, LOSS OR INTERRUPTION OF TECHNOLOGY, LOSS OF USE OF SERVICE OR EQUIPMENT, EVEN IF THE USER WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE. WE WILL ALSO HAVE NO LIABILITY FOR ANY EMERGENCY AND/OR UNFORESEEABLE INCIDENTS RELATED TO YOUR USE OF OUR SERVICES ON THE VELASPAD PLATFORM SUCH AS STOLEN PRIVATE KEY, OR HACKED ACCOUNTS.</li>
                <li>Except as expressly provided in these Terms, and to the maximum extent permitted by any Applicable Laws, we disclaim all other representations or warranties, express or implied, made to you, your affiliates, or any other person, including, without limitation, any warranties regarding the quality, suitability, merchantability, fitness for a particular purpose or otherwise (regardless of any course of dealing, custom or usage of trade) of any services provided incidental to the Services under these Terms.</li>
                <li>In no event will our aggregate liability for any loss or damage that arises in connection with the  Services exceed the purchase amount you paid to participate in the VELASPAD Platform, if any, during a twelve (12) month period immediately preceding the event that gave rise to the claim for liability. The preceding limitations of liability will apply to the fullest actual amount you paid to participate or access in the VELASPAD Platform.</li>
                <li>
                  Except as expressly provided in these Terms, and to the fullest extent permitted by any Applicable Laws, VELASPAD, our Partners, our Affiliate, and their related parties each disclaim all liability to you for any loss or damage arising out of or due to:
                  <ol>
                    <li>your use of, inability to use, or availability or unavailability of the Services, including any Third Party Services made available through the Services;</li>
                    <li>the occurrence or existence of any defect, interruption, deletion of files, delays in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction or unauthorised access to our records, programs, Services, server, or other infrastructure relating to the Services;</li>
                    <li>the Services being infected with any malicious code or viruses; or</li>
                    <li>the failure of the Services to remain operational for any period of time.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <b>Indemnification</b>
              <p>You irrevocably undertake the responsibility of fully indemnifying and holding harmless each of us, our Partners, our Affiliate, licensors, shareholders, officers, directors, managers, employees, and agents from and against any and all losses, claims, actions, proceedings, damages, demands, judgements, sums, liabilities, damages, costs, charges and expenses, including, but not limited to, any reasonable attorney's fees or penalties imposed by any regulatory authority, and reimbursements arising out of or related to the following situations:</p>
              <ol>
                <li>Your use or any person using the Services on your behalf or participation in accordance with the Services on the Website or the VELASPAD Platform;</li>
                <li>Your breach of or our enforcement of these Terms;</li>
                <li>Any violations of Applicable Laws, regulation, or rights of any third-party during your use or participate in VELASPAD Platform.</li>
              </ol>
              <p>If you are obligated to indemnify us, our Partners, our Affiliate, shareholders, licensors, officers, directors, managers, employees, and agents, we will have the right, at our sole discretion, to control any action or proceeding and to determine whether we wish to proceed, or settle, and if so, on what terms or provisions.</p>
            </li>
            <li>
              <b>Termination</b>
              <ol>
                <li>These Terms will be immediately terminated by discontinuing your use or participate in the Services and you agree to terminate the accessibility on the Platform.</li>
                <li>These Terms can be suspended or terminated without a notice from us if there is a reasonable ground to believe that you have breached any of the terms or provisions stipulated in these Terms, or if you do not comply with these Terms.</li>
                <li>The termination of these Terms will not prevent us from seeking remedies from you in the case where you breach any terms or provisions before such termination. We will not be liable to you or to any third party for any termination, suspension, or modification of your access to the Services.</li>
                <li>Any ongoing obligation to you as well as the provisions regarding (i) our intellectual property, (ii) indemnification, (iii) limitation of liability, and (iv) any other provisions designed to survive, will survive any termination or expiration of these Terms for any reason.</li>
              </ol>
            </li>
            <li>
              <b>No Financial and Legal Advice</b>
              <p>We are merely a technology platform, and we are not your broker, intermediary, agent, or legal advisor and has no fiduciary relationship or obligation to you in connection with any decisions or activities effected by you using or participating on the Website or the VELASPAD Platform. No communication or information provided to you by us is intended as or will be considered or construed as, the solicitation of an offer to buy, the investment advice, financial advice, legal advice, or any other sort of advice. All Services, Transactions, and Investments will be executed automatically based on the parameters of your consideration. You will be solely responsible for determining whether any services, or investments are suitable and match your interests according to your judgement, objectives, circumstances and risk tolerance. You will be solely responsible for any losses or liabilities therefrom.</p>
              <p>Before executing any transactions, purchasing VLXPAD Tokens or IDO Project’s tokens on the VELASPAD Platform, you should consult with your independent financial, legal, or tax professionals. We will not be liable for the decisions you make to access and purchase through the Website.</p>
            </li>
            <li>
              <b>Notice/Announcement</b>
              <p>Any notice, requests, demands, and determinations for us under these Terms (other than routine operational communications) shall be sent to <a href="mailto:support@velaspad.io">support@velaspad.io</a> at the Website.</p>
            </li>
            <li>
              <b>Governing Law, Resolving Disputes, Arbitration and Class Action Waiver</b>
              <p>PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.</p>
              <ol>
                <li>
                  Notice of Claim and Dispute Resolution Period
                  <p>Please contact us first. We will seek to address your concerns without resorting to formal legal proceedings whenever possible. If you have a dispute with us, you should contact us directly, and a case number will be assigned. We will attempt to resolve your dispute internally as soon as possible. The parties will agree to negotiate in good faith to resolve the dispute and discussions will remain confidential and subject to applicable laws protecting settlement discussions from use as evidence in any legal proceeding.</p>
                </li>
                <li>
                  Agreement to Arbitrate
                  <p>The Parties agree that subject to Clause 16.1 above, any dispute, claim, or controversy between Parties that arises in connection with, or relating in any way, to these Terms, or to your relationship with us as a user of the Services (whether by contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) will be determined by a mandatory final and binding individual arbitration rather than a class action, except as set forth below under Exceptions to the Agreement to Arbitrate. The Parties further agree that the arbitrator will have the exclusive power to rule on his or her jurisdiction, including, without limitation, any objections with respect to the existence, scope, or validity of the Agreement to Arbitrate, or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court, including, if applicable, attorney fees, except the arbitrator may not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. The arbitration provisions set forth in this Section will survive termination of these Terms.</p>
                </li>
                <li>
                  Arbitration Rules
                  <p>Any dispute arising out of or in connection with these Terms including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference in these Terms. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one arbitrator. The language of the arbitration shall be English.</p>
                </li>
                <li>
                  Governing Law/Jurisdiction
                  <p>The governing law of the arbitration will be that of the Republic of Seychelles.</p>
                </li>
                <li>
                  Confidentiality
                  <p>Parties agree that the arbitration will be kept confidential. The existence of the arbitration, any non-public information provided in the arbitration, and any submissions, orders or awards made in the arbitration will not be disclosed to any non-parties except the tribunal, the parties, their counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other person necessary to facilitate the arbitration. Notwithstanding the preceding, a party may disclose information to the extent that disclosure may be required to fulfil a legal duty, protect, or pursue a legal right, or enforce or challenge an award in bona fide legal proceedings. This confidentiality provision will survive the termination of these Terms and any arbitration brought under these Terms.</p>
                </li>
                <li>
                  Class Action Waiver
                  <p>The Parties agree that any claims relevant to these Terms, or your relationship with us will be brought against the other party in arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. The Parties further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable laws. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties involved.</p>
                </li>
                <li>We reserve the right to update, modify, revise, suspend, or make future changes to Clause 16.2 regarding the Parties' Agreement to Arbitrate, subject to applicable laws. You hereby consent and agree that it is your responsibility to ensure that your understanding of this Clause is up to date. Subject to applicable laws, your continued use of our Services will be interpreted as your acceptance of any modifications to Clause 16 regarding the Parties' Agreement to Arbitrate. You agree that if you object to the modifications to Clause 16, we may block access to your account pending closure of your account. In such circumstances, these Terms prior to modification will remain in full force and affect the pending closure of your accessibility.</li>
              </ol>
            </li>
            <li>
              <b>Miscellaneous</b>
              <ol>
                <li>
                  Severability
                  <p>If any of the provisions in these Terms is found by a court of competent authority to be invalid, void, unlawful or unenforceable under any applicable laws, such unenforceability or invalidity will not render these Terms unenforceable or invalid as a whole, and such provisions will be deleted without affecting the remaining provisions herein.</p>
                </li>
                <li>
                  Variation of Terms
                  <p>We have the right to revise these Terms at our sole discretion at any time, and by using the Website, you will be expected to review such Terms regularly to ensure that you understand all provisions stipulated in these Terms.</p>
                </li>
                <li>
                  Assignment
                  <p>We will be allowed to assign, transfer, and subcontract our rights and/or obligations under these Terms without the need to provide you any notification or acquire your consent. Nevertheless, you will not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
                </li>
                <li>
                  Entire Agreement
                  <p>These Terms, including the Privacy Policy and any rules contained on the Website, constitute the sole and entire agreement between the Parties with respect to your use of the VELASPAD Platform, and supersedes other prior or contemporaneous negotiations, discussions, agreements, understandings, representations, and warranties, both written and oral, between the Parties with respect to such subject matter.</p>
                </li>
                <li>
                  No Third-Party Rights
                  <p>Nothing in these Terms will be deemed to create any rights to any creditors or other persons, not a party hereto. Moreover, these Terms will not be construed, in any respect, to be a contract, in whole or in part, for the benefit of any third parties.</p>
                </li>
                <li>
                  Clickwrap
                  <p>We may deliver the Services through electronic means such as download links, graphical, Tools or other technologies for providing the Services for users. Interfacing to such electronic means may require the user to agree to these Terms by checking a box, clicking a button, or continuing with the Services, the user complying through such actions shall become a Party to these Terms. Such action of acceptance shall be sufficient to bind the users to the terms and conditions herein these Terms.</p>
                </li>
                <li>
                  Waiver
                  <p>The failure of one Party to require the performance of any provision will not affect the other Party's right to require performance at any time thereafter. At the same time, the waiver of one Party to seek recovery for the other Party's violation of these Terms of any provisions of applicable terms will not constitute a waiver by that Party of any subsequent breach or violation by the other Party or of the provision itself.</p>
                </li>
              </ol>
            </li>
          </ol>
        </div>

      </div>
    </>
  );
};

export default TermsOfUsePage;
