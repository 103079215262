import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { ACTION_CONST } from "../../constants";
import { addTokenToMetamask } from '../../utils/metamaskUtils'
import { isMetamaskAvailable } from "../../utils/utils";
import { formatTimeStampAllocation, formatTokenAllocation, helpers } from "../../utils/helpers";
import { useWeb3Utils } from "../../hook/useState";

const YourAllocationComponent = (props) => {

    const dispatch = useDispatch();
    const web3Utils = useWeb3Utils();

    const [layout, setLayout] = useState(1);
    const [allocationInfo, setAllocation] = useState([])

    useEffect(() => {
        if (props) {
            setAllocation(props.allocationInfo)
            setLayout(props.layout)
        }
    }, [props])


    const handleClaimClick = (index) => {
        if (web3Utils) {
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })

            web3Utils.claim({
                contractAddress: props.contractAddress,
                index: index
            }, (data) => {

                if (data.status == "CLAIM_SUCCESS") {

                    dispatch({ type: ACTION_CONST.REQUEST_DONE })

                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: "Tokens Successfully Claimed"
                    })
                    props.handleBuyClick()

                }
                if (data.status == "CLAIM_FAIL") {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: "Failed to claim tokens, Please contact support"
                    })
                    props.handleBuyClick()
                }
                //code handle event claim
            })
        }
    }

    const handleAddTokenToMetamask = async () => {
        if (props.tokenAddress) {
            addTokenToMetamask({
                tokenAddress: props.tokenAddress,
                tokenSymbol: props.tokenSymbol,
                tokenDecimals: props.decimals,
                tokenImage: ""
            }, (res) => {
                if (res.status == "ADD_TOKEN_SUCCESS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: "Successfully added token to MetaMask"
                    })
                }
                if (res.status == "ADD_TOKEN_FAILS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: "Failed to add token to MetaMask"
                    })
                }
            })
        } else {
            dispatch({
                type: ACTION_CONST.ALERT_FAILS,
                message: "Token incorrect!"
            })
        }

    }

    return (
        <>

            <div className="tab-pane fade" id="allocation" role="tabpanel" aria-labelledby="allocation-tab">
                <div className="py-3 mt-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="card d-none d-lg-block">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-bordered">
                                            <tbody>
                                                <tr className="tr-header">
                                                    <td>
                                                        <span>No.</span>
                                                    </td>
                                                    <td>
                                                        <span>Allocation</span>
                                                    </td>
                                                    {
                                                        layout === 2 &&
                                                        <td>
                                                            <span>Percentage</span>
                                                        </td>
                                                    }
                                                    <td>
                                                        <span>Date</span>
                                                    </td>
                                                    <td>
                                                        <span>Claimed</span>
                                                    </td>
                                                    {isMetamaskAvailable() &&
                                                        <td>
                                                            <span>Action</span>
                                                        </td>
                                                    }

                                                </tr>
                                                {
                                                    (allocationInfo.length > 0 && layout === 1) &&
                                                    allocationInfo.map((item, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <span>{item.no}</span>
                                                            </td>
                                                            <td>{helpers.formatNumberNumberWithDecimals(item.allocationAmount , props.decimals , 4)} {props.tokenSymbol}</td>
                                                            <td>{item.timestamp != 0 ?
                                                                <div className="text-nowrap">{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC </div>
                                                                : "DEX Listing"
                                                            }</td>
                                                            <td>{helpers.formatNumberNumberWithDecimals(item.claimedAmount, props.decimals, 4)} {props.tokenSymbol}</td>
                                                            <td>

                                                                <div className="d-inline-block">
                                                                    <div className="button-container h-auto">
                                                                        <button className="button text-nowrap pt-3 pb-2 px-3"
                                                                            style={{ fontSize: '13px' }}
                                                                            onClick={() => handleClaimClick(key)}
                                                                            disabled={!(item.status === "OPEN")}>{'Claim Tokens'}</button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))

                                                }
                                                {
                                                    (allocationInfo.length > 0 && layout === 2) &&
                                                    allocationInfo.map((item, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <span>{item.no}</span>
                                                            </td>
                                                            <td>{formatTokenAllocation(item.allocationAmount, props.decimals, 4)}</td>
                                                            <td>{(item.percentage / 100).toFixed(2)}%</td>
                                                            <td>{item.timestamp != 0 ?
                                                                <div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(item.timestamp) }}></div>
                                                                : "DEX Listing"
                                                            }</td>

                                                            <td>{helpers.formatNumberNumberWithDecimals(item.claimedAmount, props.decimals, 4)}</td>
                                                            <td>
                                                                <div className="d-inline-block">
                                                                    <div className="button-container h-auto">
                                                                        <button className="button text-nowrap pt-3 pb-2 px-3"
                                                                            style={{ fontSize: '13px' }}
                                                                            onClick={() => handleClaimClick(key)}
                                                                            disabled={!(item.status === "OPEN")}>{'Claim Tokens'}</button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="d-lg-none d-block mt-4">
                                {
                                    (allocationInfo.length > 0 && layout === 1) &&
                                    allocationInfo.map((item, key) => (
                                        <div className="allocation-card" key={key}>
                                            <div className="div-no">#{item.no}</div>
                                            <div>
                                                <span>Allocation</span>
                                                <h4>{helpers.formatNumberNumberWithDecimals(item.allocationAmount, props.decimals || 18 , 4)} {props.tokenSymbol}</h4>
                                            </div>
                                            <div>
                                                <span>Date</span>
                                                <h4>{item.timestamp != 0 ?
                                                    <div className="text-nowrap">{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC </div>
                                                    : "DEX Listing"
                                                }</h4>
                                            </div>
                                            <div>
                                                <span>Claimed</span>
                                                <h4>{helpers.formatNumberNumberWithDecimals(item.claimedAmount, props.decimals || 18 , 4)} {props.tokenSymbol}</h4>
                                            </div>
                                            <div className="mt-3">
                                                <div className="button-container h-auto">
                                                    <button className="button text-nowrap pt-3 pb-2 px-3 w-100"
                                                        style={{ fontSize: '13px' }}
                                                        onClick={() => handleClaimClick(key)}
                                                        disabled={!(item.status === "OPEN")}>{'Claim Tokens'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    (allocationInfo.length > 0 && layout === 2) &&
                                    allocationInfo.map((item, key) => {
                                      
                                        return (
                                            <div className="allocation-card" key={key}>
                                                <div className="div-no">#{item.no}</div>
                                                <div>
                                                    <span>Allocation</span>
                                                    <h4>{formatTokenAllocation(item.allocationAmount, props.decimals || 18, 4)}</h4>
                                                </div>
                                                <div>
                                                    <span>Percentage</span>
                                                    <h4>{(item.percentage / 100).toFixed(2)}%</h4>
                                                </div>
                                                <div>
                                                    <span>Date</span>
                                                    <h4>{item.timestamp != 0 ?
                                                        <div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(item.timestamp) }}></div>
                                                        : "DEX Listing"
                                                    }</h4>
                                                </div>
                                                <div>
                                                    <span>Claimed</span>
                                                    <h4>{helpers.formatNumberNumberWithDecimals(item.claimedAmount , props.decimals || 18, 4)}</h4>
                                                </div>
                                                <div className="mt-3">
                                                    <div className="button-container h-auto">
                                                        <button className="button text-nowrap pt-3 pb-2 px-3 w-100"
                                                            style={{ fontSize: '13px' }}
                                                            onClick={() => handleClaimClick(key)}
                                                            disabled={!(item.status === "OPEN")}>{'Claim Tokens'}</button>
                                                    </div>
                                                </div>
                                            </div>)
                                       
                                    } )
                                }
                            </div>
                        </div>
                        <div className="col-md-3 text-md-end text-center">
                            {
                                props.allocationInfo.length >= 0 &&
                                <div className="d-inline-block">
                                    <div className="button-container" style={{ height: '46px' }}>
                                        <a onClick={() => handleAddTokenToMetamask()} className="button text-nơwrap d-flex" style={{
                                            cursor: 'pointer',
                                            padding: '15px 15px 11px 15px',
                                            fontSize: '14px'
                                        }}>
                                            <i className="fas fa-plus me-2"></i>
                                            <span dangerouslySetInnerHTML={{ __html: 'Add token to <b>MetaMask</b>' }}></span>
                                        </a>
                                    </div>
                                </div>

                            }

                            <div className="mt-3 me-3">
                                {
                                    props.claim &&
                                    <div className="d-inline-block">
                                        <div className="button-container" style={{ height: '46px' }}>
                                            <a href={props.claim} target="blank" className="button text-nơwrap d-flex" style={{
                                                cursor: 'pointer',
                                                padding: '15px 15px 11px 15px',
                                                fontSize: '14px'
                                            }}>
                                                <i className="fas fa-plus me-2"></i>
                                                <span dangerouslySetInnerHTML={{ __html: 'Claim via Project site' }}></span>
                                            </a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};

export default YourAllocationComponent;
