import { get } from "lodash";
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";

import { ACTION_CONST, ROUTES } from "../../constants";
import { helpers } from "../../utils/helpers";
import { getCountDown } from "../../utils/helper";
import { BigNumber } from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useActiveWeb3React } from "../../hook";
import { statusSubmit, useLatestBlock, useStatusSubmit, useWeb3Utils } from "../../hook/useState";
// import { formatNumberDownRound } from "../../utils/utils";

const RightBar = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  function toggleSidebar() {
    $('.p-sidebar').toggleClass('active');
  }

  const { account, library } = useActiveWeb3React()
  const web3Utils = useWeb3Utils();
  const latestBlock = useLatestBlock()
  const [stakedAmount, setStakedAmount] = useState('0');
  const [unstakedAmount, setUnstakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);

  // const [isSubmitOK, setIsSubmitOK] = useState(false);
  const submitOK = useStatusSubmit()


  useEffect(() => {

    if (web3Utils && account) {
      // get stake amount
      web3Utils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        setUnstakedAmount(data.unstakedAmount);
        setRewardAmount(data.rewardAmount);

        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }

        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }
  }, [web3Utils, account, submitOK, dispatch, withdrawTimestamp, latestBlock])




  useEffect(() => {
    if (withdrawTimestamp > 0) {
      getCountDown(`${account}-endtime`, withdrawTimestamp, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNTDOWN_STAKE_TIME,
          data: job
        });

      }, (job) => {
        setWithdrawTimestamp(0);
      });
    }
  }, [dispatch, account, withdrawTimestamp])

  //handle button withdraw click 
  const handleExcWithDraw = () => {


    if (web3Utils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.stakingExecuteWithdrawRewards(data => {
        if (data.status === "STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t("Withdraw stake successfully")
          })

        }
        if (data.status === "STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t("Withdraw stake fail")
          })
        }
      })
    }
    //to do somrthing
  }

  //stake reward
  const handleExStakeRewards = async () => {
    if (web3Utils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.stakingRewards(data => {
        if (data.status === "STAKING_REWARDS_SUCCESS") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t("Stake rewards successfully")
          })

        }
        if (data.status === "STAKING_REWARDS_FAIL") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t("Stake rewards fail")
          })
        }
      })
    }
  }

  return (
    <>
      <div className="col-xl col-md-4">
        <div className="p-sidebar-card">
          <div className="p-sidebar-card-title">{t('Staked')}</div>
          <div className="p-sidebar-card-body">
            <div className="p-sidebar-card-value">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</div>
          </div>
        </div>
      </div>
      <div className="col-xl col-md-4">
        <div className="p-sidebar-card position-relative">
          <div className="p-sidebar-card-title">{t('Rewards')} (VLX)</div>
          <div className="p-sidebar-card-body">
            <div className="p-sidebar-card-value">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 6)}</div>
            <div className="p-sidebar-card-actions position-absolute" style={{
              bottom: '0px',
              right: '0px',
              zIndex:1
            }}>
              <button className="btn btn-light btn-round btn-sm pb-0 px-1" style={{ fontSize: '13px' }}
                disabled={!account || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                onClick={() => handleExcWithDraw()}>{t('Withdraw')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightBar;
