

export const  MODE = process.env.REACT_APP_MODE || 'TESTNET';

export const POOL_INTERVAL = 15000;

export const EXPLORER = {
    MAINNET:'https://evmexplorer.velas.com',
    TESTNET:'https://evmexplorer.testnet.velas.com'
}
export const BACKEND_URI = {
    // MAINNET:'https://staging-tp.cloud',   //
    MAINNET:'https://app.velaspad.io',  
    TESTNET:'https://staging-tp.cloud'
}

export const STAKING_CONTRACT_ADDRESS ={
    MAINNET:'0xdb0422A1C78C2064Ce5Af1B75412294F5B6D7Edf',
    TESTNET:'0x5f2DFeB7F4f42409f407d6Af56E7CAB59E20Eb0A'
}
export const NODE_URI ={
    MAINNET:['https://mainnet.velas.com/rpc'],
    TESTNET:['https://testnet.velas.com/rpc']
}


export const KYC_BACK_END_URI="https://bscpad.com"

export const supportChainIds = [111, 106];
export const defaultChainId = process.env.REACT_APP_MODE === "TESTNET" ? 111 : 106; ;

//mainnet 106; testnet 111 ; dev 5705

export const AMOUNT_SHOW_KYC = 1000.0