import { get } from "lodash";
import { useSelector } from "react-redux";


export const useWeb3Utils = () => {
    return useSelector((state) => get(state, "utils.web3Utils", null));
}

export const useLatestBlock = () => {
    return useSelector((state) => get(state, "utils.latestBlock", 0));
}

export const useStatusSubmit = () => {
    return useSelector((state) => get(state, "utils.blocking", false));

}


export const useStakingInfo = () => {
    return useSelector((state) => get(state, "wallet.stakingInfo", {}));
}

export const useStakingWalletInfo = () => {
    return useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
}

export const useVLXPADBalance = () => {
    return useSelector((state) => get(state, "wallet.VLXPADBalance", 0));
}

export const useVelasBalance = () => {
    return useSelector((state) => get(state, "wallet.ethBalance", 0));
}

export const useSelectedProject = () => {
    return useSelector((state) =>
        get(state, "project.selectedProject", null)
    );
}

export const useInfoRound = () => {
    return useSelector((state) =>
        get(state, "project.selectedProject.infoRound", [])
    );
}

export const useProjectName = () => {
    return useSelector((state) =>
        get(state, "project.selectedProject.name", "")
    );
}

export const useProjectSymbol = () => {
    return useSelector((state) =>
        get(state, "project.selectedProject.symbol", "")
    );
}


export const useIsConnected = () => {
    return useSelector((state) =>
        get(state, "utils.isConnectWallet", false)
    );
}

export const useModalHelp = () => {
    return useSelector((state) => get(state, "utils.showModalHelp", false));
}


export const useCloseProject = () => {
    return useSelector((state) =>
        get(state, "project.closedProjects", [])
    );
}

export const useOpeningProject = () => {
    return useSelector((state) =>
        get(state, "project.openingProjects", [])
    );
}

export const useWaitingProject = () => {
    return useSelector((state) =>
        get(state, "project.waitingProjects", [])
    );

}


export const useStakeAmount = () =>{
   return useSelector((state) => get(state, "wallet.stakingWalletInfo.stakedAmount", 0));
}

export const useKYCStatus = () =>{
  return  useSelector((state) => get(state, "wallet.kycStatus", null));
}