import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actGetListProjects } from "../../redux/action/user";
import WaitingProjectsComponent from "./WaitingProjectsComponent";
import OpeningProjectsComponent from "./OpeningProjectsComponent";
import ClosedProjectsComponent from "./ClosedProjectsComponent";
import { get } from "react-hook-form";
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import $ from 'jquery';

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const latestBlock = useSelector((state) => get(state, "utils.latestBlock", 0));

  useEffect(() => {
    dispatch(actGetListProjects())
  }, [latestBlock])

  useEffect(() => {
    function handleScroll(event) {
      // if (window.scrollY <= 40) {
      //   $('#BannerImage').css('transform', 'scale(1)');
      // } else {
      //   $('#BannerImage').css('transform', 'scale(.9)');
      // }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])


  return (
    <>
      <div className="projects-page">
        <Parallax className="projects-page-banner">
          <div className="projects-page-banner-text">
            <Parallax x={[30, -10]}>
              <h1>VLXPAD</h1>
              <p>The first launchpad for<br />Velas</p>
            </Parallax>
          </div>
          {/* <div className="projects-page-banner-rocket">
            <Parallax x={[120, -20]}>
              <img src="/images/rocket.png" />
            </Parallax>
          </div>

          <div className="projects-page-banner-spaceship">
            <Parallax x={[-150, 40]}>
              <img src="/images/spaceship.png" />
            </Parallax>
          </div> */}

        </Parallax>
        <div className="project-page-wrap">
          <OpeningProjectsComponent />
          <WaitingProjectsComponent />
          <ClosedProjectsComponent />
        </div>
      </div>

    </>
  );
};

export default ProjectsPage;
