import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

// import store from "./redux/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { history } from "./utils/history";
import configureStore from "./redux/store";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { NetworkContextName } from "./component/literals";
import getLibrary from "./component/modules/helper";
import { ParallaxProvider } from "react-scroll-parallax";

const { store, persistor } = configureStore({}, history);
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        {/* <PersistGate persistor={persistor}> */}
        <ParallaxProvider>
          <App />
        </ParallaxProvider>
        {/* </PersistGate> */}
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
