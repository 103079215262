import React, { useEffect, useState, } from "react";
// import BlockUi from "react-block-ui";
// import "react-block-ui/style.css";
import { get } from "lodash";
import { ACTION_CONST } from "../../constants";
// import { actGetListProjects } from "../../redux/action/user";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import { helpers } from "../../utils/helpers";
import { useSelectedProject, useWeb3Utils } from "../../hook/useState";


const InvestmentModal = (props) => {
  const dispatch = useDispatch();
  // const [showBlockUI, setShowBlockUI] = useState(false);
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();
  const [projectName, setProjectName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [amount, setAmount] = useState(0);



  const [enableBuyBtn, setEnableBuyBtn] = useState(false);


  useEffect(() => {
    if (selectedProject) {
      setProjectName(selectedProject.name)
      setSymbol(selectedProject.symbol);
    }
  }, [selectedProject]);


  //add function click max button
  const handleClickMax = () => {
    if (props.allocationNumber == 0 || props.remainingAllocation == 0) {
      setEnableBuyBtn(false);
      return;
    }

    if (props.allocationNumber > 0 && props.allocationNumber < props.remainingAllocation && props.allocationNumber < props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.allocationNumber, 4);
      setAmount(helpers.formatNumberDownRound(props.allocationNumber,4));
      setEnableBuyBtn(true);
      return;
    }



    if (props.remainingAllocation > 0 && props.remainingAllocation < props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.remainingAllocation,4);
      setAmount(helpers.formatNumberDownRound(props.remainingAllocation, 4));
      setEnableBuyBtn(true);
      return;
    }

    if (props.walletInfo.tokenBalance > 0 && props.remainingAllocation >= props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance,4);
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 4))
      setEnableBuyBtn(true);
      return;
    }

    setEnableBuyBtn(false);
  }


  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);

    // check balance of account
    console.log("remainingAllocation==>", props.remainingAllocation);
    // check allow Number

    console.log('amountInputValue==>', amountInputValue)

    if (0 < amountInputValue && amountInputValue <= props.remainingAllocation
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {

      setEnableBuyBtn(true);

    } else {
      setEnableBuyBtn(false)
    }

    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)

    return;

  }

  const handleBuyClick = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      console.log("amount==>", Number(amount));
      web3Utils.buyTokens({
        contractAddress: selectedProject.contract,
        tokenAddress: selectedProject.tokenAddress,
        amount: Number(amount)
      }, (data) => {
        if (data.status == "BUY_SUCCESS") {

          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()

          setEnableBuyBtn(false);
          document.getElementById("inputAmountSwap").value = 0;
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Successfully Joined Pool"
          })
        }

        if (data.status == "BUY_FAIL") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Failed to Join Pool"
          })
        }
      }).catch(err => {
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
        dispatch({
          type: ACTION_CONST.ALERT_FAILS,
          message: "Failed to Join Pool! Please try again!"
        })
        console.log(err);
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="buyModal"
      tabIndex="-1"
      aria-labelledby="buyModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="buyModalLabel">Join {projectName} Pool</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body p-4">
            <div className="mb-4 form-group">
              <label className="form-label">Your balance: </label>
              <h2><b>{props?.tokenBalance} {props?.symbol}</b></h2>
            </div>

            <div className="mb-3 form-group">
              <label className="form-label">{symbol} amount:</label>
              <div className="d-flex align-items-center">
                <input autoFocus={true} type="number" id="inputAmountSwap" className="form-control form-control-lg" style={{ marginRight: '-10px' }} defaultValue={0} onChange={(e) => handleOnchangeAmount(e)} />
                <div className="button-container h-auto me-2">
                  <button type="button" className="button px-3 pb-2 pt-3" onClick={() => handleClickMax()}>
                    <span>Max</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="text-center mx-2">
              <div className="d-block text-center mt-5">
                <div className="button-container mx-auto w-100">
                  <button disabled={!enableBuyBtn} onClick={() => enableBuyBtn ? handleBuyClick() : null} data-bs-dismiss="modal" className="button w-100">
                    <span>Join</span>
                  </button>
                </div>
              </div>
            </div>
            {/* </BlockUi> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentModal;
