import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { ACTION_CONST, ACTION_STATUS, ROUTES } from "../../constants";
import { EXPLORER, MODE } from "../../_configs";
import { Button } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../utils";
import { useTranslation } from "react-i18next";
import { useStakingInfo, useStakingWalletInfo, useVelasBalance, useWeb3Utils } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";

const UnStakingTab = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const { account } = useActiveWeb3React()
    const web3Utils = useWeb3Utils();
    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo()
    const velasBalance = useVelasBalance()

    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [stakedDuration, setStakedDuration] = useState(0);
    const [totalPctFee, setTotalPctFee] = useState(0);
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    useEffect(() => {

        setUnStakeStepActive();

    }, [unStakeCurrentStep])

    const cleanValue = () => {
        setUnStakeCurrentStep(1);
    };
    useImperativeHandle(ref, () => {
        return {
            cleanValue: cleanValue
        }
    });
    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {

            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                velasBalance > 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
        if (stakingInfo && stakingWalletInfo["stakedDuration"]) {
            setStakedDuration(stakingWalletInfo["stakedDuration"]);
            setTotalPctFee(stakingWalletInfo["totalPctFee"] / 100);
        }
    }, [acceptTerm, account, velasBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                web3Utils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);

                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Init Unstake fails"
                        })
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }


    function setUnStakeStepActive() {
        $('#unStake .bs-stepper-header .step').removeClass('active');
        $('#unStake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= unStakeCurrentStep) {
                $('#unStake #unStakeButtonStep' + i).addClass('active');
                $('#unStake #unStakeLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#unStake .bs-stepper-content').hide();
        $('#unStake #UnStakeStep' + unStakeCurrentStep).show();
    }

    return (

        <div className="tab-pane fade" id="unStake" role="tabpanel" aria-labelledby="unStake-tab">
            <div className="mt-3 mb-4 d-flex justify-content-center align-items-center">
                <h3 className="p-tab-title mb-0">{t('Unstake & Withdraw your VLXPAD')}</h3>
            </div>
            <div className="row justify-content-center mb-4">
                <div className="col-lg-12">
                    <div className="bs-stepper w-100">
                        <div className="bs-stepper-header" role="tablist">
                            <div className="step active" id="unStakeButtonStep1">
                                <button type="button" className="step-trigger ps-0">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label first">Warning</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep1" />
                            <div className="step" id="unStakeButtonStep2">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label">Checkpoints</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep2" />
                            <div className="step" id="unStakeButtonStep3">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label">Amount to Unstake</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep3" />
                            <div className="step" id="unStakeButtonStep4">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label">Initialize Unstake</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep4" />
                            <div className="step" id="unStakeButtonStep5">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label last">Confirmation</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="p-content-card p-content-card-steps">
                        <div className="p-sidebar-card-body">
                            {/* Step 1 */}
                            <div className="bs-stepper-content" id="UnStakeStep1">
                                <div className="row gx-xl-5">
                                    <div className="col-lg-4">
                                        <div className="step-header">
                                            <h1><i className="fas fa-exclamation-triangle me-2"></i> {t('Warning')}</h1>
                                            <p>
                                                {t('You may be subject to a fee if you wish to unstake & withdraw your VLXPAD tokens early')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="table-responsive mb-3 mt-4">
                                            <table className="table bs-stepper-table">
                                                <tbody>
                                                    <tr>
                                                        <td>{t('Staked less than 2 weeks')}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">25.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('Less than 4 weeks')}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">15.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('Less than 6 weeks')}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">10.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('Less than 8 weeks')}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">5.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('8 weeks or more')}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">0.00%</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className="mb-2">{t('You have staked for')} [{helpers.convertSecondsToReadableString(stakedDuration)}]. {t('Your current fee is')}: <b className="text-white">{totalPctFee}%</b></p>
                                        <p>{t("Please click 'Next' if you wish to proceed.")}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Step 2 */}
                            <div className="bs-stepper-content" id="UnStakeStep2">
                                <div className="row gx-xl-5">
                                    <div className="col-lg-4">
                                        <div className="step-header">
                                            <h1>{t('Checkpoints')}</h1>
                                            <p>{t('The following conditions must be met to proceed')}:</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row mt-4 d-flex gx-xl-5">
                                            <div className="col-md-6">
                                                <div className={account ? "p-select-card selected" : "p-select-card"}>
                                                    <h1>01 {account && <span>✓</span>}</h1>
                                                    <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                                    <div className="p-select-card-description">{t('If not connected, click the "Connect Wallet" button in the top right corner')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? "p-select-card selected" : "p-select-card"}>
                                                    <h1>02 {parseFloat(stakingWalletInfo["stakedAmount"]) > 0 && <span>✓</span>}</h1>
                                                    <div className="p-select-card-title"><b>{t('Have an active VLXPAD stake')}</b></div>
                                                    <div className="p-select-card-description">{t('You currently have')} {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} {t('VLXPAD staked')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={velasBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                    <h1>03 {velasBalance > 0 && <span>✓</span>}</h1>
                                                    <div className="p-select-card-title"><b>{t('VLX available in wallet')}</b></div>
                                                    <div className="p-select-card-description">
                                                        {t('VLX is required to pay transaction fees on the Velas Network.')} {t('VLX Balance')}: {helpers.formatNumberDownRoundWithExtractMax(velasBalance, 4)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="form-check">
                                                <input className="form-check-input float-none me-1"
                                                    type="checkbox" defaultValue id="flexCheckDefault"
                                                    onChange={() => setAcceptTerm(!acceptTerm)} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {t('I have read the')}&nbsp;
                                                    <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Step 3 */}
                            <div className="bs-stepper-content" id="UnStakeStep3">
                                <div className="row gx-xl-5">
                                    <div className="col-lg-4">
                                        <div className="step-header">
                                            <h1>{t('Amount to Unstake')}</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <h4 className="text-white">{t('Please enter the amount you wish to Unstake & Withdraw')}</h4>
                                        <div className="mb-0 form-group" style={{ maxWidth: '370px' }}>
                                            <label className="form-label fs-20">{t('Amount')}</label>
                                            <div className="d-flex align-items-center">
                                                <input type="number" id="inputAmountSwap" className="form-control form-control-lg form-control-input-group" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                <button className="btn btn-light btn-lg btn-input-group" onClick={() => handleMaxButtonClick()}>{t('MAX')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Step 4 */}
                            <div className="bs-stepper-content" id="UnStakeStep4">
                                <div className="text-center">
                                    {/* <div className="confirm-icon justify-content-center mx-auto align-items-center">
                                        <img className="spinnerx" src="/images/loader.svg" alt="loader" width="90" height="90" />
                                    </div>   */}
                                    <h4>{t('Confirm Unstaking & Withdraw Process')}</h4>
                                    <p>{t('In this step, you initiate the process')}</p>
                                </div>
                            </div>

                            {/* Step 5 */}
                            <div className="bs-stepper-content" id="UnStakeStep5">
                                <div className="text-center">
                                <div className="text-info">
                                        <i className="mdi mdi-check" style={{ fontSize: '60px' }} />
                                    </div>
                                    <h2 className="text-white"><b>{t('Confirmed')}</b></h2>
                                    <h4 className="mt-4">
                                        {t('You have initiated the process.')}<br />
                                        {t('If desired, you may check Velas Network to confirm the transaction.')}
                                    </h4>
                                    <p><a className="p-address" href={`${EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-8 col-md-12">
                    <div className="mb-3 mt-4 step-buttons ms-3">
                        {
                            !isSubmitOK ?
                                <>
                                    <div className="button-container me-2">
                                        <button className="button" onClick={() => unStakeBack()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 1}>
                                            <span><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</span>
                                        </button>
                                    </div>
                                    <div className="button-container">
                                        <button className="button" onClick={() => unStakeNext()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 5}>
                                            <span><i className="mdi mdi-arrow-right me-2" />{t('Next')}</span>
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="button-container me-2">
                                        <button className="button" onClick={() => submitDone()} type="button">
                                            <span>{t('Done')}</span>
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>


    );
});

export default UnStakingTab;
