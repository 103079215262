import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { history } from "../../utils/history";
import { ACTION_CONST, ROUTES } from "../../constants";
import moment from 'moment'
import "react-block-ui/style.css";
import InvestmentModal from "./InvestModal";
import { actGetListProjects, actSelectedProject, actSetCurrentContract, } from "../../redux/action/user";
import { getCountDown } from "../../utils/helper";
import { helpers } from "../../utils/helpers";
import ScheduleTabComponent from "./ScheduleTabComponent";
import YourAllocationComponent from "./YourAllocationComponent";
import { useParams } from "react-router";
import ApproveModal from "./ApproveModal";
import { useInfoRound, useIsConnected, useLatestBlock, useModalHelp, useSelectedProject, useWeb3Utils } from "../../hook/useState";

const ProjectDetailPage = () => {
  const dispatch = useDispatch();

  let params = useParams();
  const [contractAddress, setContractAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  // const [ethBalance, setEthBalance] = useState(0);
  const [remainingAllocation, setMaxTokenAllocation] = useState(0);
  const [userParticipation, setUserParticipation] = useState(0);
  const [amountPurchased, setAmountPurchased] = useState(0);
  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);
  const [projectState, setProjectState] = useState(null);
  const [tier, setTier] = useState("");
  const [roundState, setRoundState] = useState(0);
  const [textRoundState, setTextRoundState] = useState('')
  const [roundTime, setRoundTime] = useState(0);
  const [allocationInfo, setAllocationInfo] = useState([])
  const [layoutAllocation, setLayoutAllocation] = useState(1)
  const [yourAllocationVisible, setYourAllocationVisible] = useState(false)



  const [allocationNumber, setAllocationNumber] = useState(0);

  const [enableApprove, setEnableApprove] = useState(false);
  const [walletInfo, setWalletInfo] = useState({
    remainingAllocation: 0,
    tokenBalance: 0,
  })

  useEffect(() => {
    const { contract } = params
    if (contract && typeof contract === "string") {
      setContractAddress(contract);
      dispatch(actSelectedProject(contract));
      dispatch(actSetCurrentContract(contract));
    } else {
      history.push(ROUTES.HOMEPAGE);
      return;
    }
  }, [])

  const latestBlock = useLatestBlock();

  const selectedProject = useSelectedProject();

  const showModalHelp = useModalHelp();

  const isConnectWallet = useIsConnected();

  const web3Utils = useWeb3Utils();
  const roundInfo = useInfoRound();

  const [closeTime, setCloseTime] = useState(0);
  const [openTime, setOpenTime] = useState(0)
  const [fcfsOpenTime, setFcfsOpenTime] = useState(0)

  //Job interval  
  useEffect(() => {
    if (contractAddress) {
      dispatch(actSelectedProject(contractAddress))
    }

  }, [latestBlock, contractAddress])

  useEffect(() => {
    if (selectedProject) {

      if (selectedProject.closeTime !== closeTime) {

        setCloseTime(selectedProject.closeTime);
      }
      if (selectedProject.openTime != openTime) {

        setOpenTime(selectedProject.openTime)
      }
      if (selectedProject.fcfsOpenTime != fcfsOpenTime) {
        // console.log("fcfsOpenTime==>", selectedProject.fcfsOpenTime);
        setFcfsOpenTime(selectedProject.fcfsOpenTime)
      }

      setYourAllocationVisible(selectedProject.yourAllocationVisible)
      setProjectState(selectedProject)
    }

  }, [selectedProject]);

  useEffect(() => {

    if (openTime > 0) {
      // console.log("call here")
      getCountDown(`idOpenTime-${selectedProject["contract"]}`, openTime * 1000, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_OPEN,
          data: job
        })

      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }

  }, [openTime])

  useEffect(() => {
    if (closeTime > 0) {
      // console.log("idTimeClose==>", closeTime);

      getCountDown(`idTimeClose-${selectedProject["contract"]}`, closeTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_CLOSE,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [closeTime])

  useEffect(() => {
    if (fcfsOpenTime > 0) {
      // console.log("fcfsOpenTime==>", fcfsOpenTime);
      getCountDown(`idFcfsOpenTime-${selectedProject["contract"]}`, fcfsOpenTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [fcfsOpenTime])





  useEffect(() => {

    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && selectedProject) {
      getCountDown(`idRoundTime-${selectedProject["contract"]}`, roundTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })

      }, function end(job) {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
        //do smt when countdown expired
      });
    }

  }, [roundTime])


  let count = 0;
  useEffect(() => {

    if (isConnectWallet && web3Utils && projectState) {
      dispatch(actGetListProjects())
      // console.log("count===>", count++);

      fetchData()

    }
    async function fetchData() {
      const { contract } = params

      if (contract) {
        await getWalletInfo(contract);
        await getAllowance();
        await getYourAllocation(contract)
      }
    }

  }, [isConnectWallet, web3Utils, countBuy, contractAddress, projectState, latestBlock]);





  const getWalletInfo = async (contract) => {
    if (web3Utils) {
      web3Utils.getInfoWallet(contract).then(

        data => {
          // console.log(projectState);
          setWalletInfo({
            remainingAllocation: data.remainingAllocation / 10 ** projectState.decimal,
            tokenBalance: data.tokenBalance / 10 ** projectState.decimal,
            ethBalance: data.ethBalance
          })

          // setEthBalance(data.ethBalance);
          setTokenBalance(data.tokenBalance / 10 ** projectState.decimal);

          setUserParticipation(data.userParticipation / 10 ** projectState.decimal);
          setMaxTokenAllocation(data.remainingAllocation / 10 ** projectState.decimal);
          setTier(data.tier);
          setRoundState(data.roundState);
          setTextRoundState(data.roundStateText)

          if (document.getElementById('idTextRoundState')) {
            document.getElementById('idTextRoundState').innerHTML = data.roundStateText;
          }



          if (roundTime != data.roundTimestamp) {
            setRoundTime(data.roundTimestamp);
          }

          if (!(data.roundState === 1 || data.roundState === 3)) {
            // console.log('call here state ')
            setEnableJoinProject(false);
            return;
          }


          if (projectState.state === "C" || projectState.state === "P" || projectState.address === 'TBA') {
            setEnableJoinProject(false)
            return;
          }
          else {
            if (selectedProject.isPrivate) {
              if (data.remainingAllocation === 0) {

                setEnableJoinProject(false)
                return;
              } else {
                setEnableJoinProject(true)
                return;
              }
            } else {
              setEnableJoinProject(true)
              setMaxTokenAllocation(projectState.maxSingleParticipationAllocated)
              // remainingAllocation = maxSingleParticipationAllocated
            }
          }

        }
      ).catch(err => {

        console.log(err);
      })
    }

  }
  const getAllowance = async () => {
    web3Utils.getAllowance(selectedProject.tokenAddress, selectedProject.contract).then(data => {

      setAllocationNumber(Number(data))
    }).catch(err => {
      console.log(err);
    })
  }
  const getYourAllocation = (contract) => {
    web3Utils.getInfoAllocations(contract).then(data => {
      setLayoutAllocation(data.layout)
      setAllocationInfo(data.infoAllocation)
    }).catch(err => {
      console.log(err);
    })
  }

  //check enable disable approve button
  useEffect(() => {
    if (remainingAllocation > allocationNumber) {
      setEnableApprove(true)
    } else {
      setEnableApprove(false)
    }
  }, [allocationNumber, remainingAllocation])

  // console.log("check==> ", (!enableJoinProject || allocationNumber == 0));
  return (
    <>
      {selectedProject ?
        <div className="pp-detail-page">
          <div className="pp-detail-banner mb-5">
            <img className="neon-logo" src="/images/neon-logo.png" />
            <img className="cyber-girl" src="/images/cyber-girl-3.png" />


            <div className="container">
              <div className="row align-items-center justify-content-end" >
                <div className="col-xl-6 col-lg-8">
                  <div className="project-detail-info">
                    {/* Project Label */}
                    <div className="project-detail-label">
                      {(selectedProject.state === "O" || selectedProject.state === "F") && <span className="project-detail-status open"><i className="mdi mdi-circle"></i> Open</span>}
                      {selectedProject.state === "C" && <span className="project-detail-status closed"><i className="mdi mdi-circle"></i> Closed</span>}
                      {
                        selectedProject.state === "P" &&
                        <>
                          {
                            selectedProject.openTime !== undefined ?
                              <span className="project-detail-status opening"><i className="mdi mdi-circle"></i> Opens in <b id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                              : <span className="project-detail-status opening"><i className="mdi mdi-circle"></i>TBA</span>
                          }
                        </>
                      }
                      <div className="project-detail-symbol">{selectedProject.symbol}</div>
                    </div>

                    {/* Project Name */}
                    <div className="project-detail-title">
                      <div className="banner-circle circle circle--black access__circle" >
                        <div className="circle__icon">
                          <img src={selectedProject.logo} alt={selectedProject.name} />
                        </div>
                        {/* <div className="circle__text">
                          <img src="/images/circle-text-3.svg" alt="" />
                        </div> */}
                      </div>
                      {/* <div className="banner-circle circle circle--black access__circle" data-bs-toggle="modal" data-bs-target={isConnectWallet ? "#buyModal" : "#connectWalletModal"}>
                            <div className="circle__icon">
                              <img src={selectedProject.logo} alt={selectedProject.name} />
                            </div>
                            <div className="circle__text">
                              <img src="/images/circle-text-3.svg" alt="" />
                            </div>
                          </div> */}
                      <h1>{selectedProject["name"]}</h1>
                    </div>

                    {/* Project Description */}
                    <div className="project-detail-description">
                      <p className="text-dark">
                        {selectedProject.description}
                      </p>
                    </div>

                    {/* Project Socials */}
                    <div className="project-detail-social">
                      {
                        selectedProject.pancakeswap &&
                        (<a href={selectedProject.pancakeswap} target="_blank">
                          <img height="20" src="/images/pancake-swap.png" />
                        </a>)
                      }
                      {
                        selectedProject.website &&
                        (<a href={selectedProject.website} target="_blank">
                          <i className="fas fa-globe-americas"></i>
                        </a>)
                      }

                      {
                        selectedProject.twitter &&
                        (<a href={selectedProject.twitter} target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>)
                      }
                      {
                        selectedProject.medium &&
                        (<a href={selectedProject.medium} target="_blank">
                          <i className="fab fa-medium-m"></i>
                        </a>)
                      }
                      {
                        selectedProject.telegram &&
                        (<a href={selectedProject.telegram} target="_blank">
                          <i className="fab fa-telegram-plane"></i>
                        </a>)
                      }
                    </div>

                    {/* Project Progress */}
                    {selectedProject.state !== "P" &&
                      <div className="card project-detail-card mx-auto mx-lg-0">
                        <div className="card-body px-3 py-2">
                          {isConnectWallet &&
                            <>
                              <div className="pp-card-info mt-2">
                                <div className="pp-card-col">
                                  Your balance<br />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="mb-0"><strong className="text-highlight" id="idBusdBalance">{helpers.formatNumberDownRound(tokenBalance, 4)} {selectedProject.symbol}</strong></div>
                                  </div>
                                </div>
                                <div className="pp-card-col">
                                  Your approved amount:<br />
                                  <b >{helpers.formatNumberDownRound(allocationNumber, 4)} {selectedProject.symbol}</b>
                                </div>
                                <div className="pp-card-col">
                                  Your tier<br />
                                  <div className="mb-0 text-highlight"><b>{tier}</b></div>
                                </div>
                              </div>
                              <hr className="mb-2" />
                            </>
                          }

                          {/* If not connect show open time  else show round time.(if state = 4 ==> show closes)*/}
                          {
                            (selectedProject.state == "O") &&
                            <div className="pp-card-info mt-2">
                              {isConnectWallet ?
                                roundState !== 4 ?
                                  <div className="pp-card-col w-200px">
                                    <span id="idTextRoundState"> {textRoundState}</span>
                                    <br />
                                    <b id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                  </div> :
                                  <div>ROUND CLOSED</div>
                                :
                                <div className="pp-card-col w-200px">First Come First Serve <u>opens</u> in:<br />
                                  <b id={`idFcfsOpenTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                </div>
                              }
                            </div>
                          }

                          {
                            selectedProject.state == "F" &&
                            <div className="pp-card-info mt-2">
                              {isConnectWallet ?
                                roundState !== 4 ?
                                  <div className="pp-card-col w-200px">
                                    <span id="idTextRoundState"> {textRoundState}</span>
                                    <br />
                                    <b id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                  </div> :
                                  <div>ROUND CLOSED</div>
                                :
                                <div className="pp-card-col w-200px">Closing in:<br />
                                  <b id={`idTimeClose-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                </div>
                              }
                            </div>
                          }
                          {
                            selectedProject.state == "C" &&
                            <div className="pp-card-info mt-2 text-highlight">
                              <div>CLOSED</div>
                            </div>
                          }

                          {
                            isConnectWallet &&
                            <>
                              <hr className="mb-2 mt-2" />
                              <div className="pp-card-info">
                                <div className="d-flex justify-content-between w-100">
                                  <div className="pp-card-col">
                                    Swapped<br />
                                    <b id="idUseParticipation">{helpers.formatNumberDownRound(userParticipation, 4)} {selectedProject.symbol}</b>
                                    <div>
                                      <span> {`
                                    ${helpers.formatNumberDownRound(userParticipation * selectedProject.rate, 4)} ${selectedProject.projectTokenSymbol}`}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="pp-card-col text-end text w-200px">
                                    Remaining Allocation<br />
                                    <b id="idBusdMaxBuy">{helpers.formatNumberDownRound(remainingAllocation, 4)} {selectedProject.symbol}</b>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                          <hr className="mb-2 mt-2" />
                          <div className="pp-card-info">
                            <div className="d-flex justify-content-between w-100">
                              {selectedProject.state !== "O" ?
                                <div className="pp-card-col w-100">
                                  Swap progress<br />
                                  <div className="pp-card-progress">
                                    <div className="pp-card-progress-percent" style={{ width: `${(selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                    <div className="pp-card-progress-label">
                                      <span><b>{((selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                      <span><b>{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</b></span>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="pp-card-col w-100 pp-card-progress-wrap light-progress disabled">
                                  <div className="pp-card-progress text-center">
                                    <div className="pp-card-progress-percent pp-card-progress-percent-card"></div>
                                    <div className="pp-card-progress-label">
                                      <span className="participants-center" >Allocation round</span>
                                      <span className="participants-center" style={{ top: "8px" }}>{selectedProject.totalCountUserParticipated} Participants</span>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {selectedProject.state === "P" && isConnectWallet &&
                      <div className="card project-detail-card mx-auto mx-lg-0">
                        <div className="card-body py-3 px-3 px-md-4">
                          {isConnectWallet &&
                            <div className="pp-card-info mt-2">
                              <div className="pp-card-col">
                                Your balance<br />
                                {
                                  selectedProject.openTime !== undefined ?
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h4 className="mb-0"><strong id="idBusdBalance">
                                        {helpers.formatNumberDownRound(tokenBalance, 4) || 0}&nbsp;
                                        {selectedProject.symbol}
                                      </strong></h4>
                                      {
                                        selectedProject.state !== "P" &&
                                        <h6 id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate} {selectedProject.projectTokenSymbol}</h6>
                                      }
                                    </div>
                                    : <div></div>
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    }

                    {/* Buy */}
                    <div className="d-inline-flex d-buttons">
                      {
                        !isConnectWallet ?
                          <div className="button-container">
                            <a href="#" className="button" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                              <span>Connect Wallet</span>
                            </a>
                          </div>
                          :
                          <>
                            <div className="button-container me-2">
                              <button type="button" className="button" data-bs-toggle="modal" data-bs-target="#approveModal"
                                disabled={!enableApprove}>
                                <span>Approve</span>
                              </button>
                            </div>

                            <div className="button-container">
                              <button type="button" className="button" data-bs-toggle="modal" data-bs-target="#buyModal" disabled={!enableJoinProject || allocationNumber == 0}>
                                <span>Join Pool</span>
                              </button>
                            </div>
                          </>

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pp-detail-content pt-5">
            <div className="container">
              <ul className="nav nav-tabs text-end" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="home" aria-selected="true"
                    data-bs-target="#home"
                  >
                    Project details
                  </button>
                </li>
                <li className="">
                  <button className="nav-link" id="schedule-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="schedule"
                    data-bs-target="#schedule"
                    aria-selected="true">
                    Schedule
                  </button>
                </li>
                {yourAllocationVisible &&
                  <li className="">
                    <button className="nav-link" id="allocation-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="allocation"
                      data-bs-target="#allocation"
                      aria-selected="true">
                      Your Allocation
                    </button>
                  </li>
                }

              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="py-3">

                    <div className="row gx-xl-5">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">Pool information</div>
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <table className="table mb-0 pp-table-info table-bordered">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span>Opens</span>
                                    </td>
                                    {
                                      selectedProject.openTime === undefined ?
                                        <td className="text-right">TBA</td>
                                        : <td className="text-right">{moment(selectedProject.openTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC </td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>
                                      <span>FCFS Opens</span>
                                    </td>
                                    {
                                      selectedProject.fcfsOpenTime === undefined ?
                                        <td className="text-right">TBA</td>
                                        : <td className="text-right">{moment(selectedProject.fcfsOpenTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC </td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>
                                      <span>Closes</span>
                                    </td>
                                    {
                                      selectedProject.closeTime === undefined ?
                                        <td className="text-right">TBA</td>
                                        : <td className="text-right">{moment(selectedProject.closeTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>
                                      <span>Swap Rate</span>
                                    </td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td><span style={{ textTransform: 'uppercase' }} id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rateUSD.toFixed(4)} {selectedProject.projectTokenSymbol}</span></td>
                                        : <td><span style={{ textTransform: 'uppercase' }} id="idBusdConvert">TBA</span></td>
                                    }
                                  </tr>

                                  <tr>
                                    <td>
                                      <span>Cap</span>
                                    </td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td><span style={{ textTransform: 'uppercase' }}>{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</span></td>
                                        : <td><span style={{ textTransform: 'uppercase' }}>TBA</span></td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>
                                      <span>Total Users Participated</span>
                                    </td>
                                    <td className="text-right">{selectedProject.totalCountUserParticipated || 0} </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span>Total Funds Swapped</span>
                                    </td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td className="text-right">{selectedProject.totalFundParticipated.toFixed(6) || 0} {selectedProject.symbol || ""}</td>
                                        : <td className="text-right">0</td>
                                    }

                                  </tr>
                                  <tr>
                                    <td>
                                      <span>Access Type</span>
                                    </td>
                                    <td className="text-right">{selectedProject.isPrivate ? "Private" : "Public"}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-4 mt-md-0">
                        <div className="card">
                          <div className="card-header">Token information</div>
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <table className="table mb-0 pp-table-info table-bordered">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span>Name</span>
                                    </td>
                                    <td className="text-right">{selectedProject.name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span>Token Symbol</span>
                                    </td>
                                    <td className="text-right">{selectedProject.projectTokenSymbol}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <br />
                                    </td>
                                    <td className="text-right">
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ScheduleTabComponent roundInfo={roundInfo} />
                {yourAllocationVisible &&
                  <YourAllocationComponent
                    allocationInfo={allocationInfo}
                    tokenSymbol={selectedProject.projectTokenSymbol}
                    decimals={projectState.decimals}
                    contractAddress={contractAddress}
                    tokenAddress={projectState.projectTokenContract}
                    handleBuyClick={() => setCountBuy(countBuy + 1)}
                    claim={selectedProject.claim}
                    layout={layoutAllocation}
                  />
                }
              </div>
            </div>
          </div>
        </div> :
        <div className="pp-detail-page">
          <div className="container pb-5 pt-xl-0 pt-5">
            Loading...
          </div>
        </div>
      }

      <InvestmentModal walletInfo={walletInfo}
        allocationNumber={allocationNumber}
        remainingAllocation={remainingAllocation} handleBuyClick={() => setCountBuy(countBuy + 1)}
        countClick={countBuy}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 6)}
        symbol={selectedProject?.symbol}
        handleInputAmount={(n) => setAmountPurchased(n)} />

      <ApproveModal walletInfo={walletInfo}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        handleInputAmount={(n) => setAmountPurchased(n)}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)} />


    </>
  );
};

export default ProjectDetailPage;
