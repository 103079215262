import React from 'react';
import ConnectWalletModal from '../ConnectWalletModal';
import WalletModal from '../WalletModal';
import HelpModal from '../HelpModal';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import packageJson from '../../../../package.json';

export default function Footer() {
  console.log("web version==>",packageJson.version );
  return (
    <>
      <footer className="footer">
        <div className="container positon-relative">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScZ7NM9o0o3oZc_4kFAwA-_ePPZqSkYpn4ZxO9knCMB63jtdA/viewform" target="_blank" className="circle circle--black access__circle">
            <div className="circle__icon">
              <img src="/images/circle-arrow-gradient.svg" alt="" />
            </div>
            <div className="circle__text">
              <img src="/images/circle-text-3.svg" alt="" />
            </div>
          </a>
          <div className="footer-top">
            <a href="/" className="logo footer__logo">
              <img src="/images/logo.svg" alt="" />
            </a>
            <ul className="footer-menu">
              <li><Link to={ROUTES.PRIVACY_POLICY} target="_blank">PRIVACY POLICY</Link></li>
              <li><Link to={ROUTES.COOKIE_POLICY} target="_blank">COOKIES POLICY</Link></li>
              <li><Link to={ROUTES.TERMS_OF_USE} target="_blank">TERMS &amp; CONDITIONS</Link></li>
            </ul>
          </div>
          <div className="footer-bottom">
            <div className="footer-copyright">© {new Date().getFullYear()} VelasPad-v.{packageJson.version}</div>

            <div className="footer-socials">
              <a href="https://twitter.com/VelasPad" target="_blank" className="footer-socials-item">
                <img src="/images/tw.svg" alt="" />
              </a>
              <a href="https://t.me/VelasPad" target="_blank" className="footer-socials-item">
                <img src="/images/tg.svg" alt="" />
              </a>
              <a href="https://medium.com/@VelasPad" target="_blank" className="footer-socials-item">
                <img src="/images/md.svg" alt="" />
              </a>
              <a href="https://t.me/VelasPadAnn" target="_blank" className="footer-socials-item">
                <img src="/images/tg.svg" alt="" />
                <div>ann</div>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <ConnectWalletModal />
      <HelpModal />
      <WalletModal />
    </>
  );
}