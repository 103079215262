import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import WithDrawTab from "./WithDrawTab";
import StakingTab from "./StakingTab";
import UnStakingTab from "./UnStakingTab";
import { ACTION_CONST } from "../../constants";
import { getStakingContractInfo } from "../../utils/contractHelpers";
import { helpers } from "../../utils";
import RightBar from "./RightBar";
import { useTranslation } from "react-i18next";
import { useLatestBlock, useStakingInfo, useStatusSubmit } from "../../hook/useState";

const StakingPortalPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [stakerCount, setStakerCount] = useState(0);
    const [totalStakedAmount, setTotalStakedAmount] = useState(0);
    const [apyPercentage, setApyPercentage] = useState(0);
    const latestBlock = useLatestBlock();

    const stakingInfo = useStakingInfo();

    const submitOK = useStatusSubmit();

    useEffect(() => {
        // console.log("call getStakingContractInfo-->");
        try {
            getStakingContractInfo().then(data => {

                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })

        } catch (error) {
            console.log(error);
        }


    }, [dispatch, submitOK, latestBlock]);




    return (
        <div className="pp-staking">
            {
                stakingInfo["isStakingPaused"] ?
                    <div className="container mt-4 text-center d-md-block d-none">
                        <span className="pp-banner-top">
                            <i className="mdi mdi-alert-outline me-2 text-danger">
                            </i> {t('All staking functions are temporarily paused. Please check back again later.')}
                        </span>
                    </div> : <></>
            }
            <div className="page-container pp-staking-page">
                {
                    stakingInfo["isStakingPaused"] ?
                        <div className="container mt-4 text-center d-md-none d-block pp-notice">
                            <span className="pp-banner-top">
                                <i className="mdi mdi-alert-outline me-2 text-danger">
                                </i> {t('All staking functions are temporarily paused. Please check back again later.')}
                            </span>
                        </div> : <></>
                }

                <div className="p-content">
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-xl col-md-4">
                                <div className="p-sidebar-card">
                                    <div className="p-sidebar-card-title">{t('Number of Stakers')}</div>
                                    <div className="p-sidebar-card-body mt-0">
                                        <div className="p-sidebar-card-value">{stakerCount}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl col-md-4">
                                <div className="p-sidebar-card">
                                    <div className="p-sidebar-card-title">{t('Total VLXPAD Staked')}</div>
                                    <div className="p-sidebar-card-body mt-0">
                                        <div className="p-sidebar-card-value">
                                            {helpers.formatNumberDownRound(totalStakedAmount, 4)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl col-md-4">
                                <div className="p-sidebar-card">
                                    <div className="p-sidebar-card-title">{t('APY')}</div>
                                    <div className="p-sidebar-card-body mt-0">
                                        <div className="p-sidebar-card-value">
                                            {helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <RightBar />
                        </div>
                        <div className="p-content-tabs">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="stake-tab" data-bs-toggle="tab" data-bs-target="#stake" type="button" role="tab" aria-controls="stake" aria-selected="true">{t('Stake')}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="unStake-tab" data-bs-toggle="tab" onClick={() => ref.current.cleanValue()} data-bs-target="#unStake" type="button" role="tab" aria-controls="unStake" aria-selected="false">{t('Unstake')}</button>
                                </li>
                            </ul>

                            <div className="tab-content mt-5" id="myTabContent">
                                <StakingTab />
                                <UnStakingTab ref={ref} />
                                <WithDrawTab />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StakingPortalPage;
