import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ACTION_CONST } from "../../constants";
import { useCloseProject } from "../../hook/useState";


const ClosedProjectsComponent = (props) => {

  const [tempLoading, setTempLoading] = useState([]);

  const closedProjects =useCloseProject();


  return (
    <div className="project-section">
      <div className="container">
        <div className="section-text text-center">
          <h2 className="project-section-title">
            <span>Projects</span>
            <b>Closed</b>
          </h2>
        </div>

        <div className="investment-section-items">
          <div className="projects-list row gx-xl-5">
            {closedProjects.length > 0 ? closedProjects.map((item, key) => {
              return (
                <div className="col-xl-6" key={key}>
                  <div className="project-item">
                    <div className="project-item-image-wrap">
                      <div className="project-item-image">
                        <Link to={(item.contract && item.contract !== 'TBA') ? `project/${item.contract}` : `#`}>
                          <img src={item.logo} alt="#" />
                          {/* Label */}
                          {
                            item.athMultiplier &&
                            <div className="project-item-label">
                              <div><small>ATH</small></div>
                              <div><b>{item.athMultiplier}x</b></div>
                            </div>
                          }
                          <div className="project-item-label">
                            <span className="project-item-status closed"><i className="mdi mdi-circle"></i> Closed</span>
                            <span className="project-item-symbol">{item.symbol}</span>
                          </div>
                        </Link>
                      </div>
                      {/* Socials */}
                      <div className="project-item-social">
                        {
                          item.pancakeswap &&
                          <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                        }
                        {
                          item.website &&
                          <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                        }
                        {
                          item.twitter &&
                          <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                        }
                        {
                          item.medium &&
                          <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                        }
                        {
                          item.telegram &&
                          <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                        }
                      </div>
                    </div>
                    <div className="project-item-info">
                      {/* Title */}
                      <div className="project-item-title">
                        <Link to={(item.contract && item.contract !== 'TBA') ? `project/${item.contract}` : `#`}>
                          {item.name}
                        </Link>
                      </div>

                      {/* Description */}
                      <div className="project-item-description">
                        <div className="item-short-desc">{item.description}</div>
                        {item.detail && <a target="blank" href={item.detail}>Learn more</a>}
                      </div>

                      {/* Progress */}
                      <div className="project-item-prize">
                        <div className="project-item-price">
                          <div className="pp-card-col">Swap rate<br /><b className="nowrap">1 {item["symbol"]} = {(item["rateUSD"])} {item["projectTokenSymbol"]}</b></div>
                          <div className="pp-card-col text-center">Cap<br /><b>{`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}</b></div>
                          <div className="pp-card-col text-end">Access<br /><b>{item.isPrivate ? "Private" : "Public"}</b></div>
                        </div>
                        <div className="project-item-progress">
                          <div className={item.state == 'O' ? 'pp-card-progress-wrap disabled' : 'pp-card-progress-wrap'}>
                            <div className="d-flex justify-content-between align-items-center pp-card-progress-top">
                              <div className="pp-card-col">Progress</div>
                              {item.state != 'O' && <div className="pp-card-col text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>}
                            </div>
                            <div className='pp-card-progress'>
                              {(!tempLoading || tempLoading.length <= 0) && <div title="9" className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>}
                              {tempLoading && tempLoading.length > 0 && tempLoading.map((progress, idx) => {
                                return (<div key={idx} title="0" className="pp-card-progress-percent animation" style={{ width: progress.width + '%', left: progress.left + '%' }}></div>)
                              })}
                              <div className="pp-card-progress-label">
                                <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                {item.state == 'O' && <span className="participants-center"><b className="">{item['totalCountUserParticipated']}</b> Participants</span>}
                                <span className="text-allocation"><b className="">{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
              :
              <div className="text-center">
                <span>
                  No projects currently closed
                </span>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
    // </div>

  );
};

export default ClosedProjectsComponent;
