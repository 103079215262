import Web3 from "web3";
import AbiIDOContract from "../constants/abi/ido.json";
import { STAKING_CONTRACT_ADDRESS, NODE_URI, MODE } from "../_configs";
import stakingABIContract from "../constants/abi/staking.json";
import { BigNumber } from "bignumber.js";
import _ from "lodash";
import exactMath from "exact-math";

export const getInfoContract = async(addresses) => {
    const info = {};
    const ethURI = NODE_URI[MODE][_.random(0, NODE_URI.length - 1)];
    const web3 = new Web3(ethURI);


    await Promise.all(
        addresses
        .filter((address) => address !== "TBA")
        .map(async(address) => {
            if (address === "TBA") {
                info[`${address}`] = {
                    state: "P",
                    symbol: "TBA",
                    rate: 0,
                    totalCountWallet: 0,
                    totalCountUserParticipated: 0,
                    totalFundParticipated: 0,
                    maxSingleParticipationAllocated: 0,
                    maxTotalParticipationAllocated: 0,
                };
            } else {
                const tokenContract = new web3.eth.Contract(AbiIDOContract, address);
                const contractInfo = await tokenContract.methods.info().call();
                const contractInfoRound = await tokenContract.methods.infoRounds().call();
                const tokenAddress = contractInfo[0];
                const symbol = contractInfo[1];
                const decimal = parseInt(contractInfo[2]);
                const rate = parseFloat(contractInfo[3] / 10 ** 6);
                const rateUSD = parseFloat(contractInfo[4] / 10 ** 6);
                const openTime = parseInt(contractInfo[5]);
                const fcfsOpenTime = parseInt(contractInfo[6]);
                const closeTime = parseInt(contractInfo[7]);
                const totalCountWallet = contractInfo[8];
                const state = contractInfo[9];
                const totalCountUserParticipated = contractInfo[10];
                const totalFundParticipated =  exactMath.div(contractInfo[11], 10**decimal) //parseFloat(contractInfo[11] / 10 ** decimal );
                const maxTotalParticipationAllocated = 
                exactMath.div(contractInfo[12], 10**decimal)

                // parseInt(contractInfo[12].substring(0, contractInfo[12].length - decimal));
                let infoRound = [];
                for (let i = 0; i < contractInfoRound["0"].length; i++) {
                    infoRound.push({
                        round: contractInfoRound[0][i],
                        opens: contractInfoRound[1][i],
                        closes: contractInfoRound[2][i],
                    });
                }

                info[`${address}`] = {
                    tokenAddress,
                    symbol,
                    decimal,
                    rate,
                    rateUSD,
                    openTime,
                    fcfsOpenTime,
                    closeTime,
                    totalCountWallet,
                    totalCountUserParticipated,
                    totalFundParticipated,
                    maxTotalParticipationAllocated,
                    state,
                    infoRound,

                };
            }
        })
    );
    return info;
};

//get info from staking contract
export async function getStakingContractInfo() {
    const ethURI = NODE_URI[MODE][_.random(0, NODE_URI.length - 1)];
    const web3 = new Web3(ethURI);
    const stakingContract = new web3.eth.Contract(stakingABIContract, STAKING_CONTRACT_ADDRESS[MODE]);
    try {
        const info = await stakingContract.methods.info().call();
        return {
            tokenAddr: info[0],
            tokenSymbol: info[1],
            tokenDecimals: Number(info[2]),
            stakerCount: Number(info[3]),
            totalStakedAmount: BigNumber(info[4]).dividedBy(10 ** 18).toString(),
            apyPercentage: Number(info[5]),
            unstakePeriod: Number(info[6]),
            isStakingPaused: info[7],
            totalRewardsDistributed: BigNumber(info[8]).dividedBy(10 ** 18).toString(),
        };
    } catch (error) {
        console.log(error);
        return {
            tokenAddr: 0,
            tokenSymbol: 0,
            tokenDecimals: 0,
            stakerCount: 0,
            totalStakedAmount: 0,
            apyPercentage: 0,
            unstakePeriod: 0,
            isStakingPaused: 0,
            totalRewardsDistributed: 0,
        };
    }
}

// export async function getBlockNumber() {
//     const ethURI = NODE_URI[MODE][_.random(0, NODE_URI.length - 1)];
//     const web3 = new Web3(ethURI);
//     return web3.eth.getBlockNumber();
// }