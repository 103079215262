import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { ACTION_CONST, ACTION_STATUS, ROUTES } from "../../constants";
import { STAKING_CONTRACT_ADDRESS, EXPLORER, MODE } from "../../_configs";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../utils";
import { useTranslation } from "react-i18next";
import { useActiveWeb3React } from "../../hook";
import { useStakingInfo, useStakingWalletInfo, useVelasBalance, useVLXPADBalance, useWeb3Utils } from "../../hook/useState";

const StakingTab = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { account, library } = useActiveWeb3React()
    const web3Utils = useWeb3Utils()
    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo()
    const velasBalance = useVelasBalance()
    const VLXPADBalance = useVLXPADBalance()
    const [acceptTerm, setAcceptTerm] = useState(false);

    const [swapAmount, setSwapAmount] = useState('0')
    const [isMaxAmount, setIsMaxAmount] = useState(false);

    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);

    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);


    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(VLXPADBalance) > 0 &&
                velasBalance > 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, account, velasBalance, stakingInfo, stakingWalletInfo, swapCurrentStep, VLXPADBalance])


    useEffect(() => {

        setSwapStepActive();

    }, [swapCurrentStep])

    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;

        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }

        if (swapCurrentStep === 3) {
            // click approve
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                });

                const allowance = await web3Utils.getAllowance(stakingInfo["tokenAddr"], STAKING_CONTRACT_ADDRESS[MODE]);

                if (new BigNumber(allowance).gte(isMaxAmount ? new BigNumber(VLXPADBalance) : new BigNumber(swapAmount))) {

                    setSwapCurrentStep(4);
                    return dispatch({
                        type: ACTION_CONST.REQUEST_DONE
                    });

                }
                web3Utils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[MODE], amount: isMaxAmount ? VLXPADBalance : swapAmount }, (data) => {
                    //function success
                    if (data.status === "APPROVED") {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        });
                        dispatch({
                            type: ACTION_CONST.ALERT_SUCCESS,
                            message: t("Approve Tokens successfully!")
                        });
                        setSwapCurrentStep(4);
                    }
                    //function fails
                    if (data.status === "APPROVE_FAILS") {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: t("Failed to Approve Tokens!")
                        })
                    }
                })

                // debugger

            }
        } else if (swapCurrentStep === 4) {
            // click deposit
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                web3Utils.stakingDeposit({ amount: isMaxAmount ? VLXPADBalance : swapAmount }, (result) => {
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)

                        //change to result screen
                        setSwapCurrentStep(5);

                    }
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: t("Deposit stake fail!")
                        })
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }


    //handle input swap change
    const handleInputSwap = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(VLXPADBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('0');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRound(VLXPADBalance, 4));
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    const setSwapStepActive = () => {
        $('#stake .bs-stepper-header .step').removeClass('active');
        $('#stake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= swapCurrentStep) {
                $('#stake #swapButtonStep' + i).addClass('active');
                $('#stake #swapLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#stake .bs-stepper-content').hide();
        $('#stake #SwapStep' + swapCurrentStep).show();
    }
    return (
        <div className="tab-pane fade show active" id="stake" role="tabpanel" aria-labelledby="stake-tab">
            <div className="mt-3 mb-4 d-flex align-items-center">
                <div className="section-text">
                    <h2 className="project-section-title mb-0">
                        <b>Stake</b>
                        <span className="ms-3 me-0">VLXPAD</span>
                    </h2>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-12">
                    <div className="bs-stepper w-100">
                        <div className="bs-stepper-header" role="tablist">
                            <div className="step active" id="swapButtonStep1">
                                <button type="button" className="step-trigger ps-0">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label first">Checkpoints</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep1" />
                            <div className="step" id="swapButtonStep2">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label">Amount Stake</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep2" />
                            <div className="step" id="swapButtonStep3">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label">Pre-authorization</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep3" />
                            <div className="step" id="swapButtonStep4">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label">Confirm</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep4" />
                            <div className="step" id="swapButtonStep5">
                                <button type="button" className="step-trigger">
                                    <img src="/images/cir.svg" />
                                    <span className="bs-stepper-label last">Confirmation</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="p-content-card p-content-card-steps">
                        <div className="p-content-card-body">
                            {/* Step 1 */}
                            <div className="bs-stepper-content" id="SwapStep1">
                                <div className="row gx-xl-5">
                                    <div className="col-lg-4">
                                        <div className="step-header">
                                            <h1>{t('Checkpoints')}</h1>
                                            <p>{t('The following conditions must be met to proceed')}:</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row mt-4 d-flex justify-content-center gx-xl-5">
                                            <div className="col-md-6">
                                                <div className={account ? "p-select-card selected" : "p-select-card"}>
                                                    <h1>01 {account && <span>✓</span>}</h1>
                                                    <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                                    <div className="p-select-card-description">{t("If not connected, click the \"Connect Wallet\" button in the top right corner")}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={parseFloat(VLXPADBalance) > 0 ? "p-select-card selected" : "p-select-card"}>
                                                    <h1>02 {parseFloat(VLXPADBalance) > 0 && <span>✓</span>}</h1>
                                                    <div className="p-select-card-title"><b> {t('VLXPAD available to deposit')}</b></div>
                                                    <div className="p-select-card-description">{t('Current Balance')}: {helpers.formatNumberDownRoundWithExtractMax(VLXPADBalance, 4)}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={velasBalance > 0 ? "p-select-card selected" : "p-select-card"}>
                                                    <h1>03 {velasBalance > 0 && <span>✓</span>}</h1>
                                                    <div className="p-select-card-title"><b>{t('Velas  available in wallet')}</b></div>
                                                    <div className="p-select-card-description">
                                                        {t('VLX is required to pay transaction fees on the Velas Network.')} {t('VLX Balance')}: {helpers.formatNumberDownRoundWithExtractMax(velasBalance, 4)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={stakingWalletInfo["withdrawTimestamp"] === 0 ? "p-select-card selected" : "p-select-card"}>
                                                    <h1>04 {stakingWalletInfo["withdrawTimestamp"] === 0 && <span>✓</span>}</h1>
                                                    <div className="p-select-card-title"><b>{t('Eligible to stake')}</b></div>
                                                    <div className="p-select-card-description">{t('You cannot stake if you have an active VLXPAD unstake/withdrawal request')}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="form-check">
                                                <input className="form-check-input float-none me-1"
                                                    type="checkbox" defaultValue id="flexCheckDefault"
                                                    onChange={() => setAcceptTerm(!acceptTerm)} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {t('I have read the')}&nbsp;
                                                    <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Step 2 */}
                            <div className="bs-stepper-content" id="SwapStep2">
                                <div className="row gx-xl-5">
                                    <div className="col-lg-4">
                                        <div className="step-header">
                                            <h1>{t('Amount Stake')}</h1>
                                            <p className="text-white">{t('Please enter the amount of VLXPAD you want to stake')}:</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="mt-4">
                                            <h4>
                                                {t('Any VLX rewards already accumulated will be automatically paid out when staking VLXPAD.')}
                                            </h4>
                                            <h4 className="mt-4">
                                                {t('Any fees applied at Unstake &amp; Withdraw stage will be based on the date you last staked.')}
                                            </h4>
                                        </div>
                                        <div className="text-start mt-4" style={{ maxWidth: '370px' }}>
                                            <div className="mb-0 form-group">
                                                <label className="form-label fs-20">{t('Amount')}</label>
                                                <div className="d-flex align-items-center">
                                                    <input type="number" id="inputAmountSwap" className="form-control form-control-lg form-control-input-group" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                                                    <button className="btn btn-light btn-lg btn-input-group" onClick={() => handleMaxButtonClick()}>{t('MAX')}</button>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start justify-content-between mt-2">
                                                <div className="font-14">
                                                    {t('Balance')}: <b className="text-warning">{helpers.formatNumberDownRound(VLXPADBalance, 4)}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Step 3 */}
                            <div className="bs-stepper-content" id="SwapStep3">
                                <div className="row gx-xl-5">
                                    <div className="col-lg-4">
                                        <div className="step-header">
                                            <h1>{t('Pre-authorization')}</h1>
                                            <p className="text-white">{t('Required transaction 1 of 2')}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <h4 className="text-white mt-4">
                                            {t('In this step, you grant access to the staking smart contract to accept your VLXPAD')}
                                        </h4>
                                        {/* <div className="confirm-icon">
                                            <img className="spinnerx" src="/images/loader.svg" alt="loader" width="90" height="90" />
                                        </div>
                                        <h4 className="text-white">{t('Waiting for the transaction to complete')}</h4>
                                        <p>{t('Please wait for the transaction to confirm before proceeding.')}</p> */}
                                        {/* <p><a className="p-address" href={`${EXPLORER[MODE]}/address/${account}`} target="blank">{account}</a></p> */}
                                    </div>
                                </div>
                            </div>

                            {/* Step 4 */}
                            <div className="bs-stepper-content" id="SwapStep4">
                                <div className="row gx-xl-5">
                                    <div className="col-lg-4">
                                        <div className="step-header">
                                            <h1>{t('Confirm')}</h1>
                                            <p className="text-white">{t('Required transaction 2 of 2')}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <h4 className="text-white mt-4">{t('In this step, you deposit the tokens into the staking contract.')}</h4>
                                        <h4 className="text-white mt-4">{t('After this step, your tokens will be successfully staked.')}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="SwapStep5">
                                <div className="text-center">
                                    <div className="text-info">
                                        <i className="mdi mdi-check" style={{ fontSize: '60px' }} />
                                    </div>
                                    <h2 className="text-white"><b>{t('Success')}</b></h2>
                                    <h4 className="mt-4">
                                        {t('Congratulations! Your tokens are now staked.')}<br />
                                        {t('If desired, you may check Velas Network to confirm the transaction.')}
                                    </h4>
                                    <p><a className="p-address" href={`${EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-8 col-md-12">
                    <div className="mb-3 mt-4 step-buttons ms-3">
                        {
                            !isSubmitOK ?
                                <>
                                    <div className="button-container me-2">
                                        <button className="button" onClick={() => swapBack()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 1}>
                                            <span><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</span>
                                        </button>
                                    </div>
                                    <div className="button-container">
                                        <button className="button" onClick={() => swapNext()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 5}>
                                            <span><i className="mdi mdi-arrow-right me-2" />{t('Next')}</span>
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="button-container me-2 ">
                                        <button className="button" onClick={() => submitDone()} type="button">
                                            <span>{t('Done')}</span>
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>


    );
};

export default StakingTab;
