import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../../constants";
import { getKYC } from "../../../redux/services/kyc.api";
import { helpers } from "../../../utils/helpers";

import exactMath from 'exact-math';
import { getStakingStatus } from "../../../redux/services/staking";
import { useActiveWeb3React } from "../../../hook";
import { AMOUNT_SHOW_KYC } from "../../../_configs";
import { useKYCStatus, useLatestBlock, useModalHelp, useStakeAmount, useWeb3Utils } from "../../../hook/useState";
const Header = (props) => {

  const dispatch = useDispatch();
  // const [isBrowserMobile, setIsBrowserMobile] = useState(false)
  const { account, library } = useActiveWeb3React()
  const showModalHelp = useModalHelp();

  const latestBlock = useLatestBlock();
  const web3Utils = useWeb3Utils();
  const stakedAmount = useStakeAmount();
  const kycStatus = useKYCStatus();
  const [VLXPADBalance, setVLXPADBalance] = useState(0);

  //set balance
  useEffect(() => {
    if (web3Utils && account) {
      //get bnb balance
      web3Utils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      web3Utils.getTokenPadBalance().then(data => {
        dispatch({
          type: ACTION_CONST.GET_BSCPAD_BALANCE,
          data: data
        })
        setVLXPADBalance(data)
      })
    }

  }, [account, web3Utils, latestBlock]);


  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }


  // //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(VLXPADBalance) + Number(stakedAmount)) >= AMOUNT_SHOW_KYC) {
      getKYCAddress(account)
    }
  }, [account, VLXPADBalance, stakedAmount, latestBlock])

  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }




  const handGotToProject = () => {
    dispatch({
      type: ACTION_CONST.CLEAR_INTERVAL_PROJECTS_JOB
    })
  }
  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center p-0" href="https://velaspad.io/" target="_blank">
            <img src="/images/logo.svg" height="56" alt="VLXPAD" className="me-2" />
          </a>
          <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={account ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>
              <li className="nav-item me-5">
                <NavLink activeClassName="active" className="nav-link" aria-current="page" to={ROUTES.PROJECTS} onClick={handGotToProject}>
                  <span>Projects</span>
                </NavLink>
              </li>
              <li className="nav-item me-5">
                <NavLink activeClassName="active" className="nav-link" aria-current="page" to={ROUTES.STACKING} >
                  <span>Staking</span>
                </NavLink>
              </li>

              <li className="nav-item me-5">
                <a href={`https://bridge.velaspad.io/`} target="blank" aria-current="page" className="nav-link">
                  <span>Bridge</span>
                </a>
              </li>
              {
                !account ?
                  <li className="nav-item me-2 connect-wallet-button">
                    <div className="button-container">
                      <a className="button" href="#" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                        <span>Connect Wallet</span>
                      </a>
                    </div>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <div className="button-container">
                        <a className="button d-flex flex-nowrap text-nowrap" href="#" data-bs-toggle="modal" data-bs-target="#walletModal" style={{
                          paddingLeft: '20px',
                          paddingRight: '20px'
                        }}>
                          <span>{helpers.formatTransactionHash(account, 4, 4)} - <b>{helpers.formatNumberDownRoundWithExtractMax(VLXPADBalance, 4)}</b> VLXPAD</span>
                        </a>
                      </div>
                    </li>
                    {
                      kycStatus === 'START' &&
                      <li className="nav-item me-2">
                        <div className="button-container button-container-warning">
                          <button className="button d-flex flex-nowrap text-nowrap button-warning"
                            onClick={() => handleOnclickKyc()}
                            id="bnt-kyc-start" style={{
                              paddingLeft: '20px',
                              paddingRight: '20px'
                            }}>
                            <span><i className="mdi mdi-file-edit-outline me-1"></i>KYC</span>
                          </button>
                        </div>
                      </li>
                    }
                    {
                      kycStatus === 'PENDING' &&
                      <li className="nav-item me-2">
                        <div className="button-container button-container-warning">
                          <button className="button d-flex flex-nowrap text-nowrap button-warning"
                            onClick={() => handleOnclickKyc()}>
                            <span><i className="mdi mdi-clock-outline me-2"></i>KYC</span>
                          </button>
                        </div>
                      </li>
                    }
                    {
                      kycStatus === 'APPROVED' &&
                      <li className="nav-item me-2">
                        <div className="button-container button-container-success">
                          <button className="button d-flex flex-nowrap text-nowrap button-success readonly">
                            <span><i className="mdi mdi-check me-2"></i>KYC</span>
                          </button>
                        </div>
                      </li>
                    }
                    {
                      kycStatus === 'ERROR' &&
                      <li className="nav-item me-2">
                        <div className="button-container button-container-danger">
                          <button className="button d-flex flex-nowrap text-nowrap button-danger"
                            onClick={() => handleOnclickKyc()}
                          >
                            <span><i className="mdi mdi-close me-2"></i>KYC</span>
                          </button>
                        </div>
                      </li>
                    }
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
