import React from "react";
import moment from 'moment'
const ScheduleTabComponent = (props) => {
  return (
    <div className="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
      <div className="py-3 mt-3">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table mb-0 table-bordered">
                    <tbody>
                      <tr className="tr-header">
                        <td>
                          <span>Round</span>
                        </td>
                        <td>
                          <span>Opens</span>
                        </td>
                        <td>
                          <span>Closes</span>
                        </td>
                      </tr>
                      {props.roundInfo.length > 0 &&
                        props.roundInfo.map((item, key) =>
                        (
                          <tr key={key}>
                            <td>
                              <span>{item.round}</span>
                            </td>
                            <td>{moment(item.opens * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</td>
                            <td>{moment(item.closes * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTabComponent;
